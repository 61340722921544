import { observable } from "mobx";
export interface IWounuseStockAdjustment {
    assignOfferDiscount?: IWounuseStockAdjustment[];

}
export interface IWounuseStockAdjustment {
    stockadjsno?: number;
    Stockadjdate?: string;
    stkoid?: number;
    stkwid?: number;
    stockAdjwardetails?: IWounuseStockAdjustmentDetails[];
}

export interface IWounuseStockAdjustmentSearchoptions {
    searchOption?: string;
    searchValue?: string;
}

export class IWounuseStockAdjustmentModel {
    entryNo?: number;
    entryDate?: string;
    itemid?: number;
    itemName?: string;
    currentStock?: number;
    wareHouse?: string;
    wid?: number;
    barcode?: string;
    batchno?: string; 
    batchqty?: number = 0;
    addQty?: number = 0;
    lessQty?: number = 0;
    mrp?: number = 0;
    stockAdjwardetails?: IWounuseStockAdjustmentDetails[]
}

export class IWounuseStockAdjustmentDetails {
    smid?: number;
    itemName?: string;
    itemid?: number;
    barcode?: string;  
    batchno?: string;
    batchqty?: number = 0;
    addQty?: number;
    lessQty?: number;
    mrp?: number;
}

const IWStockAdjustmentModel = observable<IWounuseStockAdjustment>({
    stockadjsno: 0,
    Stockadjdate: '',
    stkoid: 0,
    stkwid: 0,
    stockAdjwardetails: []
});

export default IWStockAdjustmentModel;

