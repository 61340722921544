import { getCurrentMonthFirstDate } from "../../../common/shared/utils";

export class IDamageTransfer {
    damageId?: number = 0
    damageEntryDate?: string = new Date()?.toISOString()?.substr(0, 10)
    outletId?: number = 0
    empId?: number = 0
    outletName?: string = ""
    wId?: number = 0
    remarks?: string = ""
    type?: string = "supplier" || "warehouse";
    supplierId?: number = 0
    damageEntryOutletdetails?: IDamageEntryOutletDetails[] = []
}

export class IDamageEntryOutletDetails {
    damageDetailId?: number = 0
    damageItemId?: number = 0
    damageItemQty?: string = ""
    damageItemName?: string = ""
    damageBarcode?: string = ""
    damageBatchNo?: string = ""
    damageDescription?: string = ""
    damageDetailsid?: number = 0
    damageOutletid?: number = 0
}

export class IIDateFields {
    fromDate?: string = getCurrentMonthFirstDate()?.firstDate || "";
    toDate?: string = getCurrentMonthFirstDate()?.currentDate || "";
}