import { observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';

class BarcodeConfigStore {
    @observable itemDetls = new Map();

    getBarcodeConfigDetails = async (tempname) => {
        try {
            const itemData = await apiAgent.itemMaster.getBarcodeConfigList(tempname);
            runInAction(() => {
                this.itemDetls = JSON.parse(itemData);
            });
            return JSON.parse(itemData);
        }
        catch (error) {
            console.log(error);
        }
    }

    DeleteBarcodeConfigDetails = async (tempname) => {
        try {
            const itemData = await apiAgent.itemMaster.deleteBarcodeConfItem(tempname);
            runInAction(() => {
                this.itemDetls = JSON.parse(itemData);
            });
            return JSON.parse(itemData);
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default BarcodeConfigStore;
