import { observable } from "mobx";
export interface IOwnuseOStockAdjustment {
    assignOfferDiscount?: IOwnStockAdjustment[];

}
export interface IOwnStockAdjustment {
    stockadjsno?: number;
    Stockadjdate?: string;
    stkoid?: number;
    stkwid?: number;
    stockAdjoutdetails?: IOwnStockAdjustmentDetails[];
}

export interface IOwnStockAdjustmentSearchoptions {
    searchOption?: string;
    searchValue?: string;
}

export class IOwnStockAdjustmentModel {
    entryNo?: number;
    entryDate?: string;
    itemid?: number;
    itemName?: string;
    currentStock?: number;
    Outlet?: string;
    wid?: number;
    barcode?: string;
    batchno?: string; 
    batchqty?: number = 0;
    addQty?: number = 0;
    lessQty?: number = 0;
    mrp?: number = 0;
    stockAdjoutdetails?: IOwnStockAdjustmentDetails[]
}

export class IOwnStockAdjustmentDetails {
    smid?: number;
    itemName?: string;
    itemname?: string;
    itemid?: number;
    barcode?: string;  
    batchno?: string;
    batchqty?: number = 0;
    addQty?: number;
    lessQty?: number;
    mrp?: number;
}

const IOwnStockAdjustment = observable<IOwnStockAdjustment>({
    stockadjsno: 0,
    Stockadjdate: '',
    stkoid: 0,
    stkwid: 0,
    stockAdjoutdetails: []
});

export default IOwnStockAdjustment;
