import { observable } from "mobx";
export interface ICategoryAssignMaster{
    offerId?:number;
    offerName?:string;
    // custtype?:string;
    offerid?: number;  //New
    offercustmid?:number;  //New
    offercustDetails?:ICategoryAssignMasterDetails[];

}   
export interface ICategoryAssignMasterDetails{
    custtype?:string;
}

export interface ICategoryAssignSearchoptions{
    searchOption?:string;
    searchValue?:string;
}

const categoryAssignDetail=observable<ICategoryAssignMaster>({  
    offerId:0,
    offerName:'',
    offercustDetails:[],
});

export default categoryAssignDetail;