import React, { useState, useEffect, useRef, useMemo } from "react";

interface SearchProps {
    searchArray: Array<string>,
    searchTitles: Array<SearchOptions>,
    emitSearchArray: (filterArray) => void,
    searchEnable: (bool) => void;
    buttonChildren?: any
    children?: any
    isEmitClear?: boolean
    emitClear?: () => void;
}
class SearchOptions {
    key?: string;
    value?: string;
}
const SelectSearchCriteria: React.FC<SearchProps> = ({ searchArray, searchTitles, emitSearchArray, searchEnable, buttonChildren, children, isEmitClear, emitClear }) => {
    const [searchObj, setSearchObj] = useState<any>({});

    const [searchValue, setSearchValue] = useState('');

    function handleSearchInput(e) {
        const { name, value } = e.target;
        setSearchObj({ ...searchObj, [name]: value })
    }

    const handleChangeclear = () => {
        setSearchObj({ ...searchObj, searchOption: '' })
        setSearchValue('')
        if (isEmitClear) {
            emitClear!()
        }
    }

    const filteredData = useMemo(() => {
        searchEnable(searchValue?.length > 0 ? true : false)
        if (searchValue != '' && searchValue?.length > 0 && searchArray?.length > 0) {
            let filerArray = searchArray?.filter((stock) => {
                if (stock[searchObj?.searchOption!]?.toString()?.toLowerCase().includes(searchValue?.toLowerCase())) {
                    return stock;
                }
            })
            emitSearchArray(filerArray)
            return filerArray;
        } else {
            emitSearchArray([])
            return [];
        }
    }, [searchValue])
    return (
        <div className='SearchBox' style={{ alignItems: 'center', marginTop: '10px' }}>
            <select className="form-selected" id="Mode" name="searchOption" value={searchObj?.searchOption} onChange={handleSearchInput}>
                <option value="">Select Search Criteria</option>
                {searchTitles?.map((obj, index) => {
                    return (
                        <option value={`${obj?.key}`} key={index}>{obj?.value}</option>
                    )
                })}
            </select>
            <div className="input-group">
                <input type="text"
                    name='searchValue'
                    value={searchValue}
                    style={{width: "100%"}}
                    onChange={(e) => setSearchValue(e.target.value)}
                    className="form-control" placeholder="Search Here.." aria-label="Search Here" aria-describedby="search-button"></input>
                <div className="input-group-append">
                </div>
            </div>
            <div className='btnBox flex'>
                <button className='searchclearBtn' onClick={handleChangeclear}>Clear</button>
                {buttonChildren}
            </div>
            {children}
        </div>
    )
}

export default SelectSearchCriteria;