import { faAdd, faPenToSquare, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import useStores from '../../hooks';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas, removeDuplicates } from '../../common/shared/utils';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import moment from 'moment';

const tableHeadings = ["S.No","Item Name", "Unit name", "Barcode","Batch No", "Qty","MRP","Prod.Type","Prod.No",];
const StockToWareHouseDetail = () => {

    const navigate = useNavigate()

    const { receiptStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})

    const [stockToWrhList, setStockToWrhList] = useState<any[]>([])
    const [isSpinner, setSpinner] = useState(true);

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    async function fetchPackingPlans() {
        const [userRights, stockToWarehouseList] = await Promise.all([userCreationStore?.getUserRight('Stock_to_warehouse'), receiptStore.getStockToWarehouseDetails(0)])
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        setStockToWrhList([...stockToWarehouseList])
        setSpinner(false);
    }

    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            fetchPackingPlans();
            isInitialRender.current = false;
        } else {
        }
        return () => {
            // Cleanup logic (if needed)
        };
    }, [])

    const [isModal, setModal] = useState({ mode: 0, bool: false })

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (stockToWrhList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(stockToWrhList)
        }
    }, [stockToWrhList])

    const goToPage = (value: number) => {
        let dupDta = removeDuplicates(stockToWrhList, 'StocktoWdId')
        const currentPageList = currentPageDatas(dupDta?.slice().sort((a, b) => b.StocktoWdId - a.StocktoWdId), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <div className='container-fluid'>
            <div className='vertical-space-20'></div>
            <div className="flex alignItemCenter justifyContentCenter">
                <div className='outletInputField inputFormBox LgInputField' style={{ width: '80%' }}>
                    <div className='hrBox'>
                        <h3>Stock To Warehouse Details</h3>
                    </div>

                    <div className='vertical-space-20'></div>

                    <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={removeDuplicates(stockToWrhList, 'StocktoWdId')?.slice().sort((a, b) => b.StocktoWdId - a.StocktoWdId)}
                        searchTitles={[{ key: "itemname", value: "Item Name" }]}
                        emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                    <div className='vertical-space-20'></div>
                    <div style={{ display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center' }}>
                        <div>
                            <div className='btnBox'>
                                <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{removeDuplicates(stockToWrhList, 'StocktoWdId')?.length} Records</button>
                            </div>
                        </div>
                        <div >
                            <Stack spacing={2}>
                                <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                            </Stack>
                        </div>
                    </div>
                    <div className='vertical-space-20'></div>
                    <div className='tableListDetails'>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    {["Entry No", "Entry Date", "", "Create", "Update", "Delete"]?.map((heading, index) => {
                                        return (
                                            <th key={index}>{heading}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {(isSearch ? filteredData : currentPageData).length > 0 &&
                                    (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{val?.StocktoWdId}</td>
                                                <td>{moment(val?.StocktoWDate)?.format('DD-MMM-YYYY')}</td>
                                                <td><button disabled={!userRights?.view} className='' onClick={() => setModal({ bool: true, mode: val?.StocktoWdId })}>Details</button></td>
                                                <td><button disabled={!userRights?.add} className='Add' onClick={() => navigate('/Admin/StockToWarehouse')}><FontAwesomeIcon icon={faAdd} /></button></td>
                                                <td><button disabled={!userRights?.edit} className='Edit' onClick={() => navigate('/Admin/StockToWarehouse/' + val?.StocktoWdId)}><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                                                <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='vertical-space-20'></div>
                    <Modal show={isModal?.bool} onHide={() => setModal({ mode: 0, bool: false })} className='PriceHistoryModel'>
                        <Modal.Header closeButton>
                            <Modal.Title>Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='tableBox'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            {tableHeadings?.map((heading, index) => {
                                                return (
                                                    <th key={index}>{heading}</th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {stockToWrhList.length > 0 &&
                                            stockToWrhList.filter((item) => item.StocktoWdId === isModal?.mode).map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{key + 1}</td>
                                                        <td>{val?.itemname}</td>
                                                        <td>{val?.Unitname}</td>
                                                        <td>{val?.Barcode}</td>
                                                        <td>{val?.BatchNo}</td>
                                                        <td>{val?.Qty}</td>
                                                        <td>{val?.Mrprate}</td>
                                                        <td>{val?.Ptype}</td>
                                                        <td>{val?.pid}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default StockToWareHouseDetail