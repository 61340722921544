import { getCurrentMonthFirstDate } from "../../../common/shared/utils";

export class IOutletReturnForm {
    doM_EntryNo?: number = 0
    doM_SupplierId?: number = 0
    doM_Date?: string = new Date().toISOString().substr(0, 10)
    doM_DebitNoteNo?: string = "0"
    doM_DebitType?: string = ""
    doM_SupplierName?: string = ""
    damageoutlettransferdet?: IOutletTransferDet[] = []
}

export class IOutletTransferDet {
    doT_OUTID?: number = 0
    doT_ItemID?: number = 0
    doT_Qty?: number = 0
    doT_Rate?: number = 0
    doT_TotalAmt?: number = 0
    doT_Reason?: string = ""
    doT_Type?: string = ""
    doT_damageentrytransfermstid?: number = 0
    isChecked?: boolean = false
    master_id?: number = 0
    doT_Barcode?: string = ""
    doT_Batchno?: string = ""
    doT_damageentrytransferdid?: number = 0
    doT_supplierId?: number = 0
    doT_supplierName?: string = ""
    doT_damageEntryNo?: string = ""
    doT_itemName?: string = ""
}


export class IOutletTransferItems {
    damageoutlet?: number = 0
    itemid?: number = 0
    damage_stk?: number = 0
    rate?: number = 0
    purchasePrice?: number = 0;
    // doT_TotalAmt?: number = 0
    Remarks?: string = ""
    // doT_Type?: string = ""
    // doT_damageentrytransfermstid?: number = 0
    isChecked?: boolean = false
    damage_entry_master_id?: number = 0
    Barcode?: string = ""
    batch_no?: string = ""
    damage_entry_details_id?: number = 0
    SupplierId?: number = 0
    // doT_supplierName?: string = ""
    damage_entry_no?: string = ""
    itemname?: string = ""
}


export class IIDateFields {
    fromDate?: string = getCurrentMonthFirstDate()?.firstDate || "";
    toDate?: string = getCurrentMonthFirstDate()?.currentDate || "";
}