import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import TextField from '@mui/material/TextField';
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { IPaymentInfo } from '../model/posmodal';
import useStores from '../../../hooks';

interface CustomerInvoiceModalProps {
    hold: boolean,
    holdClose: () => void,
    getInvoice: Array<any>,
    invoicePrint: (posid: any) => void,
    generateInvoice: (invoiceObj) => void
}
const CustomerInvoiceModal: React.FC<CustomerInvoiceModalProps> = ({ hold, holdClose, getInvoice, invoicePrint, generateInvoice }) => {
    const [errors, setErrors] = useState<any>({})
    const [localFields, setLocalFields] = useState<any>({ fromdate: '', mobileno: '', billno: '' });
    const [ispaymentupdate, setispaymentupdate] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState<any>({});
    const { posMasterStore, userCreationStore } = useStores();
    const [isSuccessModal, setSuccessModal] = useState(false)
    const [isFailureModal, setFailureModal] = useState(false);

    console.log(getInvoice, 'getInvoice');

    function handleInputChange(e) {
        const { name, value } = e.target;
        setLocalFields({ ...localFields, [name]: value })
    }

    function handleGenerate() {
        // let error: any = {};
        // if (localFields?.fromdate || localFields?.mobileno || localFields?.billno) {
        //     setErrors({})
        // } else if (!localFields?.fromdate) {
        //     error.fromdate = "Enter From Date"
        // } else if (!localFields?.billno) {
        //     error.billno = "Enter Invoice No"
        // }
        // if (localFields?.mobileno && localFields?.mobileno?.length !== 10) {
        //     error.mobileno = "Enter Correct Mobile No"
        // }
        // setErrors(error);

        // if (Object.keys(error)?.length === 0) {
        generateInvoice(localFields)
        // }
    }

    function hanldeCloseDial(posid) {
        setLocalFields({ ...localFields, fromdate: '', mobileno: '', billno: '' })
        invoicePrint(posid)
    }

    function hanldeupdate(posid: number) {
        setispaymentupdate(true);
        const filterPayAmt = getInvoice?.filter((each) => each.posid === posid).map((value) => {
            setPaymentAmount(value);
        })
    }

    console.log(paymentAmount, "paymentAmountaaa");

    const handleUpdated = async () => {
        console.log(paymentAmount, "paymentAmountaaa");
        const status = await posMasterStore.UpdatePosMaster(paymentAmount);

        if (status === "Success") {

            setSuccessModal(true)
        } else {

            setFailureModal(true);
        }
    }

    function hideclosepayment() {
        setispaymentupdate(false);
    }
    const handleSuccessModalClose = () => setSuccessModal(false);
    const handleFailureModalClose = () => setFailureModal(false);

    function handleAmountPaid(event: any, name: string) {
        console.log(event.target.value, "commingvalue");
        const value = event?.target?.value
        setPaymentAmount({ ...paymentAmount, [name]: Number(value) })
    }

    const TotalAmount =
        (paymentAmount.CashAmount + paymentAmount.RedeemAmount + paymentAmount.SalesReturn +
            paymentAmount.CardAmount + paymentAmount.QRcodeAmount + paymentAmount.Onlinestoreorder +
            paymentAmount.OnlinebankTR + paymentAmount.SODEXO - paymentAmount.CoinChange) - paymentAmount.Netvalue
    
    const totalNetAmount = getInvoice?.reduce((acc, product) => acc + product.Netvalue, 0);

    return (
        <div className='container-fluid'>
            {
                ispaymentupdate ?
                    <Modal show={ispaymentupdate} onHide={hideclosepayment} className='PriceHistoryModel'>
                        <Modal.Header closeButton>
                            <Modal.Title>Update Payment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>

                                <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
                                    <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Cash  :</div>
                                    <TextField
                                        size='small'
                                        id="outlined-basic"
                                        type='number'
                                        placeholder='0.00'
                                        variant="outlined"
                                        value={paymentAmount?.CashAmount}
                                        onChange={(event) => handleAmountPaid(event, "CashAmount")}
                                        name="CashAmount"
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
                                    <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Redeem Amount:</div>
                                    <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                                        value={paymentAmount?.RedeemAmount} onChange={(event) => handleAmountPaid(event, "RedeemAmount")} name="redeemAmount" />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
                                    <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Sales Return  :</div>
                                    <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                                        value={paymentAmount?.SalesReturn} onChange={(event) => handleAmountPaid(event, "SalesReturn")} name="salesReturn" />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
                                    <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Card  :</div>
                                    <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                                        value={paymentAmount?.CardAmount} onChange={(event) => handleAmountPaid(event, "CardAmount")} name="cardAmount" />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
                                    <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>QR Code:</div>
                                    <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                                        value={paymentAmount?.QRcodeAmount} onChange={(e) => handleAmountPaid(e, "QRcodeAmount")} name="qRcodeAmount" />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
                                    <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Online Store Order :</div>
                                    <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                                        value={paymentAmount?.Onlinestoreorder} onChange={(e) => handleAmountPaid(e, "Onlinestoreorder")} name="onlinestoreorder" />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
                                    <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Online Bank Transfer :</div>
                                    <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                                        value={paymentAmount?.OnlinebankTR} onChange={(e) => handleAmountPaid(e, "OnlinebankTR")} name='onlinebankTR' />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
                                    <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>SODEXO:</div>
                                    <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                                        value={paymentAmount?.SODEXO} onChange={(e) => handleAmountPaid(e, "SODEXO")} name="sodexo" />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
                                    <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Coin Change:</div>
                                    <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                                        value={paymentAmount?.CoinChange} onChange={(e) => handleAmountPaid(e, "CoinChange")} name="CoinChange" />
                                </div>
                                {/* New */}
                                {/* <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
                                    <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Coin Change:</div>
                                    <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                                        value={paymentAmount?.CoinChange} onChange={(e) => handleAmountPaid(e, "coinChange")} name="coinChange" />
                                </div> */}

                                <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
                                    <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Payable Amount :</div>
                                    <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                                        value={(paymentAmount?.Netvalue)?.toFixed(0)} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
                                    <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Total Amount :</div>
                                    <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                                        value={TotalAmount} />
                                </div>
                                <div>
                                    <Button onClick={handleUpdated} disabled={TotalAmount !== 0}>Save</Button>
                                </div>
                                {/* <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
                                              <div style={{ fontSize: 'larger', fontWeight: '600', color: balanceAmountDisplay > 0 ? 'red' : 'green', }}>{balanceAmountDisplay < 0 ? "Amount should be Return" : "Amount Should be Paid"} :</div>
                                              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined" value={Math.abs(balanceAmountDisplay)?.toFixed(0)} />
                                            </div> */}

                                {/* {error?.amountShortage && <p style={{ color: 'red' }}>{error?.amountShortage}</p>} */}
                            </div>
                        </Modal.Body>
                    </Modal> : null
            }


            <Modal show={hold} size='xl' onHide={holdClose} className='invoiceListModel' style={{ marginTop: '50px' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Invoice List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <div className='flex justifyContentCenter' style={{ margin: "15px"}}>
                        <p className='invoice-list-title'>Total No of Bills: <span className='invoice-list-titleValue'>{getInvoice?.length}</span></p>
                        <p className='invoice-list-title'>Total Sales Value: <span className='invoice-list-titleValue'>{totalNetAmount}</span></p>
                    </div>

                    <div className='inputFormBox '>
                        <div className="inputBoxLists" style={{ padding: '0px 0px 10px' }}>
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label>From Date <span>*</span></label>
                                        <input type="date" style={{ width: '100%' }}
                                            name="fromdate"
                                            onChange={handleInputChange} value={localFields?.fromdate}
                                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                        ></input>
                                    </div>
                                    {errors.fromdate && <p style={{ color: 'red' }}>{errors.fromdate}</p>}
                                </div>
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label>Invoice No <span>*</span></label>
                                        <input type="text" style={{ width: '100%' }}
                                            name="billno"
                                            onChange={handleInputChange} value={localFields?.billno}
                                            placeholder="Search Invoice..." data-date="" data-date-format="DD MM YYYY"
                                        ></input>
                                    </div>
                                    {errors.billno && <p style={{ color: 'red' }}>{errors.billno}</p>}
                                </div>
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label>Mobile No <span>*</span></label>
                                        <input type="text" style={{ width: '100%' }}
                                            name="mobileno"
                                            onChange={handleInputChange} value={localFields?.mobileno}
                                            placeholder="Search Mobile..." data-date="" data-date-format="DD MM YYYY"
                                        ></input>
                                    </div>
                                    {errors.mobileno && <p style={{ color: 'red' }}>{errors.mobileno}</p>}
                                </div>
                                <div className="col-sm-3">
                                    <button className='dfBtn' type='submit' onClick={handleGenerate} >Generate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='tableBox'>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Customer Name</th>
                                    <th scope="col">Mobile No</th>
                                    <th scope="col">Bill No</th>
                                    <th scope="col">Bill Date</th>
                                    <th scope="col">Net value</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {getInvoice?.length > 0 && getInvoice.map((val, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{val.membername}</td>
                                            <td>{val.Mobileno}</td>
                                            <td>{val.Billno}</td>
                                            <td>{moment(val.Billdate)?.format('DD-MMM-YYYY')}</td>
                                            <td>{val.Netvalue}</td>
                                            <td><button className='dfBtn width-auto' style={{ backgroundColor: 'green', color: 'white' }} onClick={() => hanldeupdate(val?.posid)}>Update<FontAwesomeIcon icon={faEdit} /></button></td>
                                            <td><button className='dfBtn viewbillBtn width-auto' onClick={() => hanldeCloseDial(val?.posid)}>View<FontAwesomeIcon icon={faEdit} /></button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                    <div className='Details Success'>
                        <div className='imgBox'>
                            {/* <Image src={require('../../../../../gsmecom/images/checked.png')} /> */}
                        </div>

                        <h4>Succesfully Submitted</h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                    <div className='Details Success'>

                        <div className='imgBox'>
                            {/* <Image src={require('../../../../gsmecom/images/warning.png')} /> */}
                        </div>
                        <h4>Failed</h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CustomerInvoiceModal