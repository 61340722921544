import React, { useState, useEffect, useRef, } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import AutoComplete from '../../common/shared/autoComplete';
import _ from 'lodash';
import { removeDuplicates } from '../../common/shared/utils';
import { useParams } from 'react-router-dom';
import { IStockAdjustmentDetails, IStockAdjustmentModel } from './model';
import stockAdjustmentValidation from './validation';
import { Autocomplete, TextField } from '@mui/material';
import Cookies from 'js-cookie';

const StockAdjustmentOutlet = observer((): JSX.Element => {
    const { commonStore, posMasterStore, stockAdjustmentOutletStore, outwardtoOutletStore, itemMasterStore, supplierMasterStore } = useStores();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any>({});
    const { loadEmployee, loadManageOfferOrDiscount, loadOutlet, loadOutlets } = commonStore;
    const { loadPOSProductList } = posMasterStore;
    const { getItemMasters, GETItemwiseStockAll } = itemMasterStore;
    const [isBatchno, setIsBatchno] = useState(false);
    const [isBarcodeNo, setIsBarcodeNo] = useState(false);
    const [itemMasterList, setItemMasterList] = useState<any[]>([])
    const [batchList, setBatchList] = useState<any[]>([])
    const [barcodeList, setBarcodeList] = useState<any[]>([])
    const [batchBarcodeList, setBatchBarcodeList] = useState<any[]>([])
    const [isLess, setLess] = useState(false);
    const [isAdd, setAdd] = useState(false);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const { stockadjsid } = useParams<{ stockadjsid: string }>();
    const [isReqQtyModal, setReqQtyModal] = useState(false);
    const [isLoadModal, setLoadModal] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canView, setCanView] = useState(false);

    async function fetchPreLoadngData() {
        setLoading(false)
        await Promise.all([
            // loadEmployee(),
            // loadManageOfferOrDiscount(),
            // outwardtoOutletStore.loadOutletItems(),
            loadOutlet()
        ])
        // const itemMasterList = await getItemMasters();
        const itemMasterList = await GETItemwiseStockAll(1, 0, '', 1);
        setItemMasterList([...itemMasterList])
        console.log(itemMasterList, 'itemMasterList');
        if (!stockadjsid) {
            // await outwardtoOutletStore.getEntryNo('StockadjOutlet');
            const entryNos = await outwardtoOutletStore.getEntryNo('StockadjOutlet');
            console.log(entryNos, "entryNosssd");
            stockAdjustment.entryNo = entryNos?.entryNo ? Number(entryNos?.entryNo) + 1 : 1;
        }
    }
    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
    }
    const isInitialRender = useRef(true);


    // useEffect(() => {
    //     if (isInitialRender.current) {
    //         fetchPreLoadngData();
    //         getManageOfferDet();
    //         isInitialRender.current = false;
    //     } else {
    //     }
    //     return () => {
    //     };
    // }, []);


    const isCurrenPage = useRef(true)
    const empid = Number(Cookies.get('userId'));
    const formName = 'StockAdjustment_Outlet';
    useEffect(() => {

        const fetchRights = async () => {

            try {
                const data = await supplierMasterStore.fetchRights(empid, formName);

                if (Array.isArray(data) && data.length > 0) {

                    const specificFieldValue = data[0].sts;
                    const rightsValue = data[0].rights;
                    const lettersArray = rightsValue.split('');
                    if (specificFieldValue === 1) {
                        if (isCurrenPage.current) {
                            fetchPreLoadngData();
                            getManageOfferDet();
                            isCurrenPage.current = false
                            if (lettersArray.includes('a')) {
                                setCanAdd(true);
                            } else {
                                navigate('/Admin/StockAdjustmentList/Outlet');
                            }
                            if (lettersArray.includes('e')) {
                                setCanEdit(true);
                            }
                            // if (lettersArray.includes('v')) {
                            //   setCanView(true); 
                            // }     
                            // if (lettersArray.includes('p')) {
                            //   setCanPrint(true); 
                            // }       
                            // if (lettersArray.includes('u')) {
                            //   setCanUpdate(true); 
                            // }                     
                        }
                    } else if (specificFieldValue === 0) {
                        setLoadModal(true)
                    }
                } else {
                    setLoadModal(true)
                }

            } catch (error) {
                console.error("Error fetching rights:", error);
            }
        };
        fetchRights();
    }, [empid, formName]);



    const handleCancel = () => {
        navigate('/Admin/StockAdjustmentList/Outlet');
    }
    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        navigate('/Admin/StockAdjustmentList/Outlet');
    }

    const [options, setOptions] = useState<any>({});

    const [stockAdjustmentDetails, setStockAdjustmentDetails] = useState<IStockAdjustmentDetails>(new IStockAdjustmentDetails())
    const [stockAdjustment, setStockAdjustment] = useState<IStockAdjustmentModel>(new IStockAdjustmentModel())

    console.log(stockAdjustment, "stockAdjustmentsss");

    const handleReqQtyModalClose = () => setReqQtyModal(false);

    const handleInputChange = (name) => (event) => {

        const { name, value } = event.target;
        console.log(typeof name, value, "valuecomming");
        if (name === 'altQtyAdd') {
            if (value > 0) {
                setAdd(true)
            } else {
                setAdd(false)
            }
            setStockAdjustment({ ...stockAdjustment, addQty: Number(value), lessQty: Number(0) });
        }
        if (name === 'altQtyLess') {
            if (Number(value) > Number(stockAdjustment?.batchqty)) {
                setReqQtyModal(true);
            }
            else {
                setReqQtyModal(false);
            }

            if (value > 0) {
                setLess(true)
            } else {
                setLess(false)
            }
            setStockAdjustment({ ...stockAdjustment, lessQty: Number(value), addQty: Number(0) });
        }
        if (name === 'MRP') {
            console.log('thisfunctionwork');
            setStockAdjustment({ ...stockAdjustment, mrp: Number(value) });
        }

        if (name === 'altBarcode') {
            setStockAdjustment({ ...stockAdjustment, barcode: value?.toString() });
        }
        if (name === 'altBatchNo') {
            setStockAdjustment({ ...stockAdjustment, batchno: value });
        }
    }


    console.log(stockAdjustment, "stockAdjustmentcon");

    const handleInputChangeDate = (e) => {
        const { name, value } = e.target;
        setStockAdjustment({ ...stockAdjustment, [name]: value });
    }

    async function handleAutoComplete(name, value) {
        console.log(value, 'valuestockAdjustment');

        if (name === "barcode" && value != null) {
            const itemMasterList = await GETItemwiseStockAll(3, value.itemid, value?.Barcode.toString(), 1);
            setBatchList(itemMasterList);
            setBatchBarcodeList(itemMasterList);
            console.log(itemMasterList, "dditemMasterList");
            // const batchList = batchBarcodeList?.filter((batchfil) => batchfil?.Barcode === value?.Barcode);
            // setBatchList(batchList);
            // setBatchBarcodeList(itemMasterList);
            setStockAdjustment({ ...stockAdjustment, [name]: value?.Barcode?.toString() })
        } else if (name === "batchno" && value != null) {
            setStockAdjustment({ ...stockAdjustment, [name]: value?.batchno, mrp: value?.mrp, batchqty: Number(value?.stock) })
        } else if (name === "wid" && value != null) {
            setStockAdjustment({ ...stockAdjustment, [name]: value?.outletid })
        }
    }

    function addOfferToGrid(e: React.FormEvent) {

        if (Number(stockAdjustment?.lessQty) > Number(stockAdjustment?.batchqty)) {
            setReqQtyModal(true);
        }
        else {
            setReqQtyModal(false);
        }

        e.preventDefault();
        let error: any = {}
        error = stockAdjustmentValidation(stockAdjustment, options);
        setErrors(error);

        if (!stockAdjustment.stockAdjoutdetails) {
            stockAdjustment.stockAdjoutdetails = [];
        }
        // const isDuplicate = stockAdjustment.stockAdjoutdetails.some(
        //     detail => detail.itemid === stockAdjustment.itemid && detail.barcode === stockAdjustment.barcode && detail.batchno === stockAdjustment.batchno
        // );

        console.log(Object.keys(error), "commingerrorlength");

        if (Object.keys(error)?.length === 0) {
            stockAdjustment.stockAdjoutdetails.push({
                itemid: stockAdjustment.itemid || 0,
                barcode: stockAdjustment.barcode?.toString() || '',
                batchno: stockAdjustment.batchno || '',
                addQty: Number(stockAdjustment.addQty) || 0,
                lessQty: Number(stockAdjustment.lessQty) || 0,
                itemName: stockAdjustment.itemName || '',
                mrp: Number(stockAdjustment.mrp) || 0,
            });
            setStockAdjustment({
                ...stockAdjustment, itemid: 0, addQty: 0, lessQty: 0, itemName: '',
                barcode: '', batchno: '', mrp: 0, batchqty: 0, currentStock: 0
            });
            setLess(false);
            setAdd(false);
            setIsBatchno(false);
            setIsBarcodeNo(false);
            setErrors({});
        }
    }


    async function getManageOfferDet() {
        // if (stockadjsid !== undefined) {
        //     try {
        //         const StockAdjustmentOutletdata = await outwardtoOutletStore.getStockAdjustmentOutlet(0, 0);
        //         console.log(StockAdjustmentOutletdata, 'StockAdjustmentOutletdata');

        //         const StockAdjustmentDet = await outwardtoOutletStore.getStockAdjustmentOutlet(stockadjsid, stockadjsid);
        //         // console.log(offDetdata, 'offDetdata');
        //         // console.log(stockadjsid, 'offerId');

        //         if (Array.isArray(StockAdjustmentDet) && StockAdjustmentDet.length > 0) {

        //             //   const updatedQtyoffArray = offDetdata.map(plan => ({
        //             //     ...qtyofferdetail,
        //             //     rowId: Number(plan.offerId), 
        //             //     from_QV: plan.from_QV ?? '',
        //             //     to_QV: plan.to_QV ?? '',
        //             //     discount_PV: plan.discount_PV ?? '',
        //             //     dis_Mode: plan.dis_Mode ?? '',
        //             //     couponName: plan.copponName ?? ''
        //             //   }));

        //             //   setQtyofferdetails(updatedQtyoffArray);

        //             const updatedMasters = {
        //                 ...stockAdjustment,
        //                 entryNo: Number(StockAdjustmentOutletdata[0]?.offerId),
        //                 entryDate: StockAdjustmentOutletdata[0]?.offerId,
        //                 itemid: Number(StockAdjustmentOutletdata[0]?.offerId),
        //                 itemName: StockAdjustmentOutletdata[0]?.offerId,
        //                 currentStock: Number(StockAdjustmentOutletdata[0]?.offerId),
        //                 Outlet: StockAdjustmentOutletdata[0]?.offerId,
        //                 wid: Number(StockAdjustmentOutletdata[0]?.offerId),
        //                 barcode: StockAdjustmentOutletdata[0]?.offerId,
        //                 batchno: StockAdjustmentOutletdata[0]?.offerId,
        //                 addQty: Number(StockAdjustmentOutletdata[0]?.offerId),
        //                 lessQty: Number(StockAdjustmentOutletdata[0]?.offerId),
        //                 //   qtyofferdetail: [
        //                 //     ...(offerDiscount.qtyofferdetail || []), 
        //                 //     ...updatedQtyoffArray
        //                 //   ]
        //             };
        //             setStockAdjustment(updatedMasters)
        //         }

        //     } catch (error) {

        //     }
        // }
    }

    function handleRemove(index) {
        stockAdjustment?.stockAdjoutdetails?.splice(index, 1)
        setStockAdjustment(_.cloneDeep(stockAdjustment))
    }

    async function saveStockAdjustmentOutlet(e) {
        let error: any = {}
        // error = saveValidation(assignOffer)
        setErrors({ ...error })
        if (Object.keys(error)?.length === 0) {
            setLoading(true);
            const entryNos = await outwardtoOutletStore.getEntryNo('StockadjOutlet');
            stockAdjustment.entryNo = entryNos?.entryNo ? Number(entryNos?.entryNo) + 1 : 1;
            const status = await stockAdjustmentOutletStore.saveStockAdjustmentOutlet(stockAdjustment);
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true)
            }
            else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    const handleItems = async (val) => {
        console.log(val, 'valuedata');
        if (val != null) {
            try {
                // const getcurrentStock = await posMasterStore.loadPOSProductList(stockAdjustment?.wid);
                const itemMasterList = await GETItemwiseStockAll(2, val?.itemid, '', 1);
                console.log(itemMasterList, "dfdfitemMasterList");
                const filtergetcurrentStock = itemMasterList?.filter(each => each.itemid === val.itemId);
                console.log(filtergetcurrentStock, "getcurrentStock");
                // // Function to filter and sum the amounts
                // const getTotalCompleted = () => {
                //     return filtergetcurrentStock
                //         .reduce((total, filtergetcurrentStock) => total + filtergetcurrentStock.stock, 0);
                //     // Sum the amounts
                // };

                // console.log(getTotalCompleted, 'getTotalCompleted');
                // // const stockTotSum = 
                // console.log(filtergetcurrentStock, 'getcurrentStock');

                setStockAdjustment({
                    ...stockAdjustment, itemid: val.itemid, itemName: val.itemname,
                    currentStock: val?.stock,
                })

                setBarcodeList(itemMasterList);

                console.log(barcodeList, 'barcode');


            } catch (error) {

            }
        }
    };

    console.log(stockAdjustment, 'stockAdjustment');

    // const isCurrentPage = useRef(true)
    // useEffect(() => {
    //     if (isCurrentPage.current) {
    //         fetchPreLoadngData();
    //         isCurrentPage.current = false;
    //     }
    //     return () => { }
    // }, []);


    if (isLoadModal) {
        return (
            <div className="container">
                {isLoadModal && <h1>Access Denied</h1>}
                <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Access Denied</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    else {

        return (

            <>
                {
                    isLoading ?
                        <div className='SpinnerBox'>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div> :
                        <>

                            <div className='container'>

                                <div className='vertical-space-20'></div>
                                <div className='outletInputField inputFormBox LgInputField'>
                                    <div className='hrBox'>
                                        <h3>Stock Adjustment Entry - Outlet</h3>
                                    </div>
                                    <div className='inputBoxLists pd-b-0'>
                                        <div className='LgInputListsBox mr-left-0'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Entry No </label>
                                                        <input type="text" className='' style={{ width: "100%" }}
                                                            //   onChange={handleInputchnage}
                                                            name="oMId"
                                                            value={stockAdjustment.entryNo}
                                                            // = outwardtoOutletStore?.entryNo?.entryNo ? Number(outwardtoOutletStore?.entryNo?.entryNo) + 1 : 1}
                                                            placeholder='Entry No..' disabled></input>
                                                    </div>
                                                    <div className='vertical-space-10'></div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Date <span>*</span></label>
                                                        <input
                                                            defaultValue={new Date().toISOString().substr(0, 10)}
                                                            value={stockAdjustment.entryDate = stockAdjustment?.entryDate ?? new Date().toISOString().substr(0, 10)}
                                                            type="date" style={{ width: "100%" }}
                                                            onChange={handleInputChangeDate}
                                                            name="date"
                                                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                                        {errors.date && <p style={{ color: 'red' }}>{errors.date}</p>}
                                                    </div>
                                                    <div className='vertical-space-10'></div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='inputBox'>
                                                        <label>Outlet <span>*</span></label>
                                                        <AutoComplete placeholder='Select Outlet..' clsName='full-width' getOptionLabel='outletName'
                                                            emitOption={(option: any) => handleAutoComplete("wid", option)} options={loadOutlets} />
                                                        {errors.assignItemId && <p style={{ color: 'red' }}>{errors.assignItemId}</p>}
                                                    </div>
                                                    <div className='vertical-space-10'></div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="inputBox">
                                                        <label>
                                                            Product <span>*</span>
                                                        </label>
                                                        {/* <AutoComplete
                                                        //  value={stockAdjustment?.assignOfferItemName}
                                                        clsName="full-width"
                                                        getOptionLabel="itemName"
                                                        options={itemMasterList}
                                                        emitOption={(val) => handleItems(val)}
                                                        placeholder="Select Product..."
                                                    /> */}

                                                        <AutoComplete
                                                            options={itemMasterList}
                                                            value={stockAdjustment?.itemName}
                                                            emitOption={(newValue) => handleItems(newValue)}
                                                            placeholder={'Select Item..'}
                                                            clsName='full-width'
                                                            getOptionLabel='itemname'
                                                        />
                                                        <div className='vertical-space-10'></div>

                                                        {/* <Autocomplete
                                                        disablePortal
                                                        options={itemMasterList}
                                                        getOptionLabel={(option) => option.itemname || " "}
                                                        sx={{ width: 550 }}
                                                        onChange={(event, newValue) => { handleItems(newValue); console.log(newValue, "dd") }}
                                                        value={stockAdjustment?.itemName}
                                                        renderInput={(params) => <TextField {...params} label="Select Product..." />}
                                                        // filterOptions={(options, { inputValue }) => {
                                                        //     return options.filter((option) =>
                                                        //         option.itemname.toLowerCase().includes(inputValue.toLowerCase())
                                                        //     );
                                                        // }}
                                                    /> */}
                                                        {/* <select className="form-control" id="ProdName" onChange={}>
                                                             <option value="">Select Product...</option>
                                                                {itemMasterList.length > 0 ? (
                                                                    itemMasterList.map((item) => (
                                                                        <option key={item.ItemId} value={item.ItemId}>
                                                                            {item.itemName}
                                                                        </option>
                                                                    ))   
                                                                ) : (
                                                                    <option value=""></option>
                                                                )}
                                                              </select> */}
                                                        {/* <AutoComplete placeholder='Select Item Name..' clsName='full-width' getOptionLabel='itemName'
                                                        emitOption={(val) => handleItems('itemid', val)}
                                                        options={itemMasterList} /> */}
                                                        {errors.assignItemId && <p style={{ color: 'red' }}>{errors.assignItemId}</p>}
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="inputBox">
                                                            <label>Current Stock</label>
                                                            <input
                                                                value={stockAdjustment?.currentStock}
                                                                type="text"
                                                                className=''
                                                                style={{ width: "100%" }}
                                                                name="oMId"
                                                                placeholder='Current Stock..'
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="vertical-space-10"></div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className='inputradio mt-3'>
                                                            <input
                                                                type='checkbox'
                                                                checked={isBarcodeNo}
                                                                onChange={() => { setIsBarcodeNo(!isBarcodeNo); setIsBatchno(!isBatchno) }}
                                                                name='newBarcode'>
                                                            </input>
                                                            <label className="mx-2">New Barcode </label>
                                                        </div>
                                                    </div>

                                                    {isBarcodeNo === false ? (
                                                        <div className="col-md-4">
                                                            <div className="inputBox">
                                                                <label>Barcode<span>*</span></label>
                                                                <AutoComplete
                                                                    clsName="full-width"
                                                                    getOptionLabel="Barcode"
                                                                    value={stockAdjustment?.barcode}
                                                                    options={barcodeList}
                                                                    emitOption={(option) => handleAutoComplete("barcode", option)}
                                                                    placeholder="Select Barcode..."
                                                                />
                                                            </div>
                                                            {errors?.barcode && <p style={{ color: 'red' }}>{errors?.barcode}</p>}
                                                            <div className="vertical-space-10"></div>
                                                        </div>
                                                    ) : (
                                                        <div className="col-md-4">
                                                            <div className="inputBox">
                                                                <label>Barcode<span>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    className=""
                                                                    style={{ width: "100%" }}
                                                                    value={stockAdjustment?.barcode}
                                                                    name="altBarcode"
                                                                    onChange={handleInputChange("batchno")}
                                                                    placeholder="Enter Alternative batchno..."
                                                                />
                                                            </div>
                                                            {errors?.barcode && <p style={{ color: 'red' }}>{errors?.barcode}</p>}
                                                            <div className="vertical-space-10"></div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className='inputradio mt-3'>
                                                            <input
                                                                type='checkbox'
                                                                name='newbatch'
                                                                checked={isBatchno}
                                                                onChange={() => { setIsBatchno(!isBatchno) }}>
                                                            </input>
                                                            <label className="mx-2">New Batchno </label>
                                                        </div>
                                                    </div>

                                                    {isBatchno === true ? (
                                                        <>
                                                            <div className="col-md-4">
                                                                <div className="inputBox">
                                                                    <label>Batch No<span>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        style={{ width: "100%" }}
                                                                        name="altBatchNo"
                                                                        onChange={handleInputChange("altBatchNo")}
                                                                        placeholder="Enter Alternative Batch No..."
                                                                    />
                                                                </div>
                                                                {errors?.batchno && <p style={{ color: 'red' }}>{errors?.batchno}</p>}
                                                                <div className="vertical-space-10"></div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="inputBox">
                                                                    <label>Batch Qty</label>
                                                                    <input
                                                                        disabled
                                                                        value={stockAdjustment?.batchqty}
                                                                        type="text"
                                                                        style={{ width: "100%" }}
                                                                        name="altBatchQty"
                                                                        placeholder="Batch Qty..."
                                                                    />
                                                                </div>
                                                                <div className="vertical-space-10"></div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="col-md-4">
                                                                <div className="inputBox">
                                                                    <label>Batch No<span>*</span></label>
                                                                    <AutoComplete
                                                                        clsName="full-width"
                                                                        getOptionLabel="batchno"
                                                                        options={batchList}
                                                                        value={stockAdjustment?.batchno}
                                                                        emitOption={(option) => handleAutoComplete("batchno", option)}
                                                                        placeholder="Select Batch No..."
                                                                    />
                                                                </div>
                                                                {errors?.batchno && <p style={{ color: 'red' }}>{errors?.batchno}</p>}
                                                                <div className="vertical-space-10"></div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <div className="inputBox">
                                                                    <label>Batch Qty</label>
                                                                    <input
                                                                        disabled
                                                                        value={stockAdjustment?.batchqty}
                                                                        type="text"
                                                                        style={{ width: "100%" }}
                                                                        name="altBatchQty"
                                                                        placeholder="Batch Qty..."
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>

                                                <div className="row">
                                                    <div className="vertical-space-10"></div>
                                                    <div className="col-md-4">
                                                        <div className="inputBox">
                                                            <label>MRP<span>*</span></label>
                                                            <input
                                                                type="number"
                                                                className=""
                                                                style={{ width: "100%" }}
                                                                value={stockAdjustment?.mrp}
                                                                name="MRP"
                                                                onChange={handleInputChange("mrp")}
                                                                placeholder="Enter MRP..."
                                                            />
                                                        </div>
                                                        {errors?.mrp && <p style={{ color: 'red' }}>{errors?.mrp}</p>}
                                                        <div className="vertical-space-10"></div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="inputBox">
                                                            <label>Qty Add<span>*</span></label>
                                                            <input
                                                                disabled={isLess}
                                                                type="number"
                                                                className=""
                                                                value={stockAdjustment?.addQty}
                                                                style={{ width: "100%" }}
                                                                name="altQtyAdd"
                                                                onChange={handleInputChange("qtyadd")}
                                                                placeholder="Enter Qty Add..."
                                                            />
                                                        </div>
                                                        {errors?.addQty && <p style={{ color: 'red' }}>{errors?.addQty}</p>}
                                                        <div className="vertical-space-10"></div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="inputBox">
                                                            <label>
                                                                Qty Less<span>*</span>
                                                            </label>
                                                            <input
                                                                disabled={isAdd}
                                                                type="number"
                                                                className=""
                                                                style={{ width: "100%" }}
                                                                name="altQtyLess"
                                                                value={stockAdjustment?.lessQty}
                                                                onChange={handleInputChange("qtyless")}
                                                                placeholder="Enter Qty Less..."
                                                            />
                                                        </div>
                                                        {errors?.lessQty && <p style={{ color: 'red' }}>{errors?.lessQty}</p>}
                                                        <div className="vertical-space-10"></div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-2'>
                                                    <button className='dfBtn pd-0-12 full-width mr-1 ' type='submit' onClick={addOfferToGrid} >ADD</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                        <div className=''>
                                            <div className='tableBox'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Item Name</th>
                                                            <th scope="col">Barcode</th>
                                                            <th scope="col">Batch No</th>
                                                            <th scope="col">MRP</th>
                                                            <th scope="col">Qty Add</th>
                                                            <th scope="col">Qty Less</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {stockAdjustment?.stockAdjoutdetails?.map((val, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>{val?.itemName}</td>
                                                                    <td>{val?.barcode}</td>
                                                                    <td>{val?.batchno}</td>
                                                                    <td>{val?.mrp}</td>
                                                                    <td>{val?.addQty}</td>
                                                                    <td>{val?.lessQty}</td>
                                                                    {/* <td>{moment(val.assignEffectiveFromDate).format("DD-MMM-YYYY HH:MM")}</td>
                                                                <td>{moment(val.assignEffectiveToDate).format("DD-MMM-YYYY HH:MM")}</td> */}
                                                                    <td>
                                                                        <button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit'
                                                                            onClick={() => handleRemove(key)}>Remove</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                                {errors?.assignOffetDetails && <p style={{ color: 'red' }}>{errors?.assignOffetDetails}</p>}
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </div>
                                    </div>
                                    <div className='btnBox'>
                                        <button className='secondaryBtn' onClick={handleCancel}>List</button>
                                        <button className='dfBtn' type='submit' onClick={saveStockAdjustmentOutlet} >Submit</button>
                                    </div>
                                </div>
                                <Modal show={isReqQtyModal} onHide={handleReqQtyModalClose} className='SubmitModal'>
                                    <Modal.Body>
                                        <div className='Details Success'>
                                            <div className='imgBox'>
                                                <Image src={require('../../../gsmecom/images/warning.png')} />
                                            </div>

                                            {/* <h4>This BatchNo:{outwardOutlet.batchNo} already reached batch quantity</h4> */}
                                            <h4> Please Check Less Qty</h4>
                                            <h4>Actual Batch Quantity:{stockAdjustment.batchqty}</h4>
                                            <h4>Entered Less Quantity:{stockAdjustment.lessQty}</h4>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleReqQtyModalClose}>
                                            Ok
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                    <Modal.Body>
                                        <div className='Details Success'>
                                            <div className='imgBox'>
                                                <Image src={require('../../../gsmecom/images/checked.png')} />
                                            </div>

                                            <h4>Succesfully Submitted</h4>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleSuccessModalClose}>
                                            Ok
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                    <Modal.Body>
                                        <div className='Details Success'>

                                            <div className='imgBox'>
                                                <Image src={require('../../../gsmecom/images/warning.png')} />
                                            </div>
                                            <h4>Failed</h4>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleFailureModalClose}>
                                            Ok
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </>
                }
            </>
        );
    }

});

export default StockAdjustmentOutlet;
