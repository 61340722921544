import { faAdd, faPenToSquare, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import useStores from '../../hooks';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas, removeDuplicates } from '../../common/shared/utils';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Console } from 'console';

const tableHeadings = ["Packingid", "Product Date", "Team Name", "Machine Name", "", "", "Create", "Update", "Delete"];
const GrindingProductionList = () => {

  const navigate = useNavigate()

  const { salesReplaceRetrunStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const [isSpinner, setSpinner] = useState(true);
  const [isModal, setModal] = useState({ mode: 0, bool: false })
  const [isLoading, setLoading] = useState(true);
  const [totalPages, setTotalPage] = useState(1);
  const [loadProductionList, setLoadProductionList] = useState<any[]>([])
  const [currentPageData, setCurrentPageData] = useState<any[]>([])
  const [returnWastageDetail, setReturnWasterDetail] = useState<any[]>([])
  const [productionDetails, setProductionDetails] = useState([]);
  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  async function fetchPackingPlans() {

    setSpinner(false);
    const [userRights, loadProductionList] = await Promise.all([
      userCreationStore?.getUserRight('Production_entry'),
      salesReplaceRetrunStore.getgrindingEntryList(1, 1)]);
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    console.log(loadProductionList, 'loadProductionList');

    setLoadProductionList(loadProductionList)
  }

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      fetchPackingPlans();
      isInitialRender.current = false;
    } else {
    }
    return () => {
      // Cleanup logic (if needed)
    };
  }, [])


  useEffect(() => {
    if (loadProductionList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(loadProductionList)
    }
  }, [loadProductionList])

  const deleteComboPackMaster = async (pid: any) => {
    setLoading(true);
    const status = await salesReplaceRetrunStore.deletegrindingMaster(pid);
    if (status === 'Success') {
      fetchPackingPlans();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const goToPage = (value: number) => {
    let removeDup = removeDuplicates(loadProductionList, 'Pid')
    const currentPageList = currentPageDatas(removeDup?.slice()?.sort((a, b) => b.Pid - a.Pid), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };



  const byAccountShow = async (mode, vocno) => {
    setSpinner(true)
    let body = {
      mode: mode,
      pid: vocno,
    }
    const productionEntry = await salesReplaceRetrunStore.getProductionEntryDetails(body);
    if (mode === 1) {
      setProductionDetails(productionEntry);
    } else {
      setReturnWasterDetail(productionEntry);
    }
    setModal({ mode: mode, bool: true })
    setSpinner(false)
  }

  const getProductionDetail = async (mode, vocno) => {

    const Data = await salesReplaceRetrunStore.getgrindingEntryList(2, vocno);

    console.log(Data, 'filteredData');

    setProductionDetails(Data)
    setModal({ mode: mode, bool: true })
    setSpinner(false)

  }

  return (
    <div className='container-fluid'>
      <div className='vertical-space-20'></div>
      <div className="flex alignItemCenter justifyContentCenter">
        <div className='outletInputField inputFormBox LgInputField' style={{ width: '80%' }}>
          <div className='hrBox'>
            <h3>Grinding Production Details</h3>
          </div>
          <div className='vertical-space-20'></div>
          <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={currentPageData}
            searchTitles={[{ key: "TeamName", value: "Team Name" }, { key: "MachineName", value: "Machine Name" }, { key: "itemname", value: "Item Name" }]}
            emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
          <div className='vertical-space-20'></div>
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center' }}>
            <div>
              <div className='btnBox'>
                <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{loadProductionList?.length} Records</button>
              </div>
            </div>
            <div >
              <Stack spacing={2}>
                <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
              </Stack>
            </div>
          </div>
          {isSpinner && <ProgressBar />}
          <div className='vertical-space-20'></div>
          <div className='tableListDetails'>
            <table className="table table-striped">
              <thead>
                <tr>
                  {tableHeadings?.map((heading, index) => {
                    return (
                      <th key={index}>{heading}</th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {(isSearch ? filteredData : currentPageData).length > 0 &&
                  (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                    return (
                      <tr key={key}>
                        <td>{val?.pid}</td>
                        <td>{moment(val?.proddate).format("DD-MMM-YYYY")}</td>
                        <td>{val?.TeamName}</td>
                        <td>{val?.MachineName}</td>
                        <td><button disabled={!userRights?.view} onClick={() => getProductionDetail(1, val?.pid)} className='tablemodeltext'> Details</button></td>
                        <td><button disabled={!userRights?.add} className='Add' onClick={() => navigate('/Admin/GrindingProduction')}><FontAwesomeIcon icon={faAdd} /></button></td>
                        <td><button disabled={!userRights?.edit} className='Edit' onClick={() => navigate('/Admin/GrindingProduction/' + val?.Pid)}><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                        <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteComboPackMaster(val?.pid)} /></button></td>
                      </tr>
                    )
                  })
                }
              </tbody>

            </table>
          </div>

          <div className='vertical-space-20'></div>
          <Modal show={isModal?.bool} onHide={() => setModal({ mode: 0, bool: false })} className='PriceHistoryModel'>
            <Modal.Header closeButton>
              <Modal.Title>{`${isModal?.mode === 2 ? 'Return/Wastage' : 'Grinding Production'}`} Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isModal?.mode === 2 ? <div className='tableBox'>

              </div> : <div className='tableBox'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>S.No</th>
                      <th scope='col'>Item Name</th>
                      <th scope='col'>Barcode</th>
                      <th scope='col'>Batch No</th>
                      <th scope='col'>Qty</th>
                      <th scope='col'>UOM</th>
                      <th scope='col'>Mrp</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productionDetails?.map((val: any, key: any) => {
                      return (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{val?.itemname}</td>
                          <td>{val?.Barcode}</td>
                          <td>{val?.batchno}</td>
                          <td>{val?.qty}</td>
                          <td>{val?.uom}</td>
                          <td>{val?.mrp}</td>
                        </tr>
                      )
                    })
                    }
                  </tbody>
                </table>
              </div>}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  )
}


export default GrindingProductionList;