import { observer } from 'mobx-react'
import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Accordion, Button, Image, Modal, Spinner } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import ProducationEntryValidation, { ProductionEntryDetValidation, ProductionItemsValidation } from './validation';
import AutoComplete from '../../common/shared/autoComplete';
import { useTranslation } from 'react-i18next';
import useStores from '../../hooks';
import { IProductinDetails, IProductionEntry, IProductionEntryDet, IProductionWastedDet, ProductionEntryDetails } from './model';
import ProductionEntryValidation from './validation';
import { QRCode } from 'react-qrcode-logo';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { removeDuplicates } from '../../common/shared/utils';
import { Autocomplete } from '@mui/material';
import Cookies from 'js-cookie';
import { debug } from 'console';
import { each } from 'chart.js/dist/helpers/helpers.core';

const ProducationEntry = observer((): JSX.Element => {

    const { supplierMasterStore, outletManagerStore, commonStore, salesReplaceRetrunStore, machineMasterStore } = useStores();
    const { loadTeamDetail, loadTeamDetails, loadItemMaster, loadItemMasters, } = commonStore;
    const { loadPackingPlanItem, saveProductEntrySave, loadPackingDistributionItem, loadPackingDistributionItems } = salesReplaceRetrunStore;

    const { t } = useTranslation()
    const sideNavAccordian: any = t("sideNavAccordian");

    const navigate = useNavigate();
    const { Pid } = useParams()

    const [loadPackingPlanItems, setLoadPackingItems] = useState<any[]>([])

    console.log(loadPackingPlanItems, 'loadPackingPlanItems>>>');


    const [tempPackingPlanItems, setTempPackingItems] = useState<any[]>([])

    const [isLoadModal, setLoadModal] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canView, setCanView] = useState(false);
    // const [canPrint, setCanPrint] = useState(false);  
    // const [canUpdate, setCanUpdate] = useState(false); 

    const [productionDet, setProductionDet] = useState<IProductinDetails>(new IProductinDetails())
    const [productionEntryDet, setProductionEntryDet] = useState<IProductionEntryDet>(new IProductionEntryDet())
    const [productionWastedDet, setProductionWastedDet] = useState<IProductionWastedDet>(new IProductionWastedDet())
    console.log(productionEntryDet, 'productionEntryDet');
    console.log(productionDet,'filterloadproductionDet');
    
    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [errors, setError] = useState<any>({})


    function handleAutoCompletes(name: string, value: any) {
        if (name === "teamid" && value !== null) {
            setProductionDet({ ...productionDet, [name]: value?.Teamid })
            console.log(loadPackingPlanItems?.filter((each) => each?.Teamid === value?.Teamid), 'loadPackingPlanItems>>')
        } else if (name === "machineid" && value !== null) {
            setProductionDet({ ...productionDet, [name]: value?.Machineid })
            console.log(loadPackingPlanItems?.filter((each) => each?.Teamid === productionDet?.teamid && each?.Machineid === value?.Machineid), 'loadPackingPlanItems>>')
        } else if (name === "itemid" && value !== null) {
            setProductionEntryDet({ ...productionEntryDet, [name]: value?.Productid, itemname: value?.itemname, packingdid: value?.packingdid })
            console.log(loadPackingPlanItems?.filter((each) =>
                each?.Teamid === productionDet?.teamid &&
                each?.Machineid === productionDet?.machineid &&
                each?.Productid == value?.Productid), 'loadPackingPlanItems>>')
        } else if (name === 'barcode') {
            setProductionEntryDet({ ...productionEntryDet, [name]: value?.barcode })
        } else if (name === 'batchno') {
            setProductionEntryDet({ ...productionEntryDet, [name]: value?.Batchno, balQty: value?.qty,Mrprate: value?.Mrprate})
            console.log(loadPackingPlanItems?.filter((each) =>
                each?.Teamid === productionDet?.teamid &&
                each?.Machineid === value?.Machineid &&
                each?.Productid == value?.Productid), 'loadPackingPlanItems>>')
        } else if (name === 'packingid') {
            setProductionEntryDet({ ...productionEntryDet, [name]: value?.packingid })
        }
    }

    function handleQtyChange(e) {
        const { name, value } = e?.target;
        if (name === "qty" && value !== undefined) {
            let error: any = {}
            const assignQty = Number(value);
            if (assignQty! > Number(productionEntryDet?.balQty)) {
                error.qty = "Exceeds from Batch Quantity"
            }
            setError(error);
            setProductionEntryDet({ ...productionEntryDet, [name]: Number(value) })
        }
    }

    function handleEntryAdd() {
        let error: any = {};
        console.log(productionEntryDet, 'productionEntryDet<<<')
        error = ProductionEntryDetValidation(productionEntryDet);
        if (Object.keys(error)?.length === 0) {
            let addedItemSum = 0;
            if (!productionDet?.productionentrydetails) {
                productionDet.productionentrydetails = Array<IProductionEntryDet>()
            }
            if (productionDet?.productionentrydetails?.length) {
                addedItemSum = productionDet?.productionentrydetails?.reduce((a, v) => a = a +
                    Number(v?.itemid === productionEntryDet?.itemid &&
                        v?.batchno == productionEntryDet?.batchno ?
                        (v?.qty ?? 0) : 0), 0) + productionEntryDet?.qty!;
            }
            let findItem = loadPackingPlanItems?.find((each) =>
                each?.Machineid == productionDet?.machineid &&
                each?.Teamid === productionDet?.teamid &&
                each?.Productid === productionEntryDet?.itemid &&
                each?.Batchno == productionEntryDet?.batchno)
            if (findItem && addedItemSum > findItem?.qty) {
                error.qty = `Packing Qty is ${findItem?.qty}. Total Qty cannot be added more the Packing Qty`;
            } else {
                productionDet?.productionentrydetails?.push(productionEntryDet)
                setProductionDet(productionDet)
                setProductionEntryDet({ ...productionEntryDet, itemid: 0, itemname: '',barcode:'',balQty: '', batchno: '', qty: 0, packingdid: 0 })
            }
        }
        setError(error)
    }

    useEffect(() => {
        handleOutRequestItemList()
    }, [productionDet?.productionentrydetails?.length])

    function handleOutRequestItemList() {
        let tempWPackingItems = [...tempPackingPlanItems];
        if (tempWPackingItems?.length) {
            let updatedList: any[] = [];
            tempWPackingItems?.map((obj, index) => {
                let filterItems = productionDet?.productionentrydetails?.filter((item) => item?.itemid === obj?.Productid && item?.batchno === obj?.Batchno)
                let findBalQty = filterItems?.reduce((acc, valu) => acc = acc + Number(valu?.qty ?? 0), 0)
                if (findBalQty! < obj?.qty) {
                    updatedList?.push(obj)
                }
            })
            setLoadPackingItems([...updatedList])
        }
    }

    function handleRemove(index, type: 'Entry' | 'Waste') {
        if (index >= 0 && type === 'Waste') {
            productionDet?.productionwastedetails?.splice(index, 1)
            setProductionDet(_.cloneDeep(productionDet))
        } else if (index >= 0 && type === 'Entry') {
            productionDet?.productionentrydetails?.splice(index, 1)
            setProductionDet(_.cloneDeep(productionDet))
        }
    }

    function handleAutoComplete(name: string, val: any) {
        if (val != null && name === "itemid") {
            setProductionWastedDet({ ...productionWastedDet, itemid: val?.PackingItemid, })
        } else if (val != null && name == 'batchno') {
            setProductionWastedDet({ ...productionWastedDet, batchno: val?.Batchno })

        }
    }

    function handleWastedAdd() {
        let error: any = {};
        if (Object.keys(error)?.length === 0) {
            if (!productionDet?.productionwastedetails) {
                productionDet.productionwastedetails = Array<IProductionWastedDet>()
            }
            productionDet.productionwastedetails.push(productionWastedDet)
            setProductionDet(_.cloneDeep(productionDet))
            setProductionWastedDet(new IProductionWastedDet())
        }
    }

    async function handleSubmit() {
        let error: any = {};
        error = ProductionEntryValidation(productionDet)
        setError(error)

        if (Object.keys(error)?.length === 0) {
            const sumbitArr = productionDet?.productionentrydetails?.map((obj) => {
                let newObj = {
                    ...obj,
                    pid: productionDet?.pid
                }
                return newObj
            })

            productionDet.productionentrydetails = sumbitArr

            let submitWastedArr: IProductionWastedDet[] = [];
            if (!productionDet?.productionwastedetails) {
                let newObj: IProductionWastedDet = {
                    itemid: 0,
                    batchno: '',
                    wastage: 0,
                    wRemarks: '',
                    returnQty: 0,
                    rremarks: '',
                    pid: productionDet?.pid
                }
                submitWastedArr?.push(newObj)
            } else {
                submitWastedArr = productionDet?.productionwastedetails?.map((obj) => {
                    let newObj = {
                        ...obj,
                        pid: productionDet?.pid
                    }
                    return newObj
                })
            }

            productionDet.productionwastedetails = submitWastedArr
            // const status = "Success";
            const status = await saveProductEntrySave(productionDet);

            if (status === "Success") {
                setSuccessModal(true);
                setLoading(false)
            } else {
                setFailureModal(true)
                setLoading(false)
            }
        }
    }

    function handleDetails() {
        navigate('/Admin/ProductionEntry/ProductionEntryDetail')
    }

    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
    }

    const isInitialRender = useRef(true);

    async function initialFetchApi() {

        setLoading(false)


    }

    async function getPakingPlanDet() {
        let body = {
            mode: 1,
            pid: Number(Pid),
        }
        if (Pid) {

            const [loadPackingPlanItems] = await Promise.all([
                loadPackingPlanItem(), loadTeamDetail(),
                loadItemMaster(), loadPackingDistributionItem(), machineMasterStore.getMachineMasterList()
            ]);

            setLoadPackingItems([...loadPackingPlanItems])
            setTempPackingItems([...loadPackingPlanItems])

            const loaddata = await salesReplaceRetrunStore.getProductionEntryList();
            let filterData = loaddata.filter((item) => Number(item.Pid) === Number(Pid));
            console.log(filterData, 'filterData>>');

            const productionDet2 = await salesReplaceRetrunStore.getProductionEntryDetails(body);
            console.log(productionDet2, 'productionDet2');

            setProductionDet((prev) => ({
                ...prev,
                teamid: Number(filterData[0]?.Teamid) || 0,
                pid: Number(Pid) || 0,
                machineid: Number(filterData[0]?.machineid) || 0,
                productionentrydetails: [
                    ...(prev.productionentrydetails || []),
                    ...productionDet2.map((item) => ({
                        itemid: item?.itemid || '',
                        qty: item?.qty || '',
                        barcode: item?.barcode || '',
                        batchno: item?.batchno || '',
                        itemname: item?.itemname || '',
                        packingdid: Number(item?.Packingdid) || '',
                        packingid: Number(item?.packingid) || '',
                    })),
                ],
            }));            
                                                
        } else {

            const [entryNos, loadPackingPlanItems] = await Promise.all([
                outletManagerStore.getEntryNo('Production'), loadPackingPlanItem(), loadTeamDetail(),
                loadItemMaster(), loadPackingDistributionItem(), machineMasterStore.getMachineMasterList()
            ]);

            setProductionDet({ ...productionDet, pid: (entryNos?.entryNo + 1) })
            setLoadPackingItems([...loadPackingPlanItems])
            setTempPackingItems([...loadPackingPlanItems])
            // if (paymentList) {

            //     mapToData(paymentList)
            //     setStckToWrhsSave({ ...stckToWrhsSave, stocktoWId: Number(Pid) })
            // }
        }


    }

    // useEffect(() => {y
    //     if (isInitialRender.current) {
    //         initialFetchApi();
    //         isInitialRender.current = false;
    //     } else {
    //     }
    //     return () => {
    //         // Cleanup logic (if needed)
    //     };
    // }, [])

      useEffect(() => {
       console.log(productionDet,'useEffectproductionDet');
       
    }, [productionDet])

    const isCurrenPage = useRef(true)
    const empid = Number(Cookies.get('userId'));
    const formName = 'Production_entry';

    useEffect(() => {

        const fetchRights = async () => {

            try {
                const data = await supplierMasterStore.fetchRights(empid, formName);

                if (Array.isArray(data) && data.length > 0) {
                    const specificFieldValue = data[0].sts;
                    const rightsValue = data[0].rights;
                    const lettersArray = rightsValue.split('');
                    if (specificFieldValue === 1) {
                        if (isCurrenPage.current) {
                            initialFetchApi();
                            getPakingPlanDet()
                            isCurrenPage.current = false
                            if (lettersArray.includes('a')) {
                                setCanAdd(true);
                            } else {
                                navigate('/Admin/ProductionEntry/ProductionEntryDetail')
                            }
                            if (lettersArray.includes('e')) {
                                setCanEdit(true);
                            }
                            // if (lettersArray.includes('v')) {
                            //   setCanView(true); 
                            // }     
                            // if (lettersArray.includes('p')) {
                            //   setCanPrint(true); 
                            // }       
                            // if (lettersArray.includes('u')) {
                            //   setCanUpdate(true); 
                            // }                     
                        }
                    } else if (specificFieldValue === 0) {
                        setLoadModal(true)
                    }
                }

            } catch (error) {
                console.error("Error fetching rights:", error);
            }
        };
        fetchRights();
    }, [empid, formName]);

    const handleFailureModalClose = () => setFailureModal(false);

    if (isLoadModal) {
        return (
            <div className="container">
                {isLoadModal && <h1>Access Denied</h1>}
                <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Access Denied</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    else {


        return (
            <>
                {/* {isLoading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> : */}
                <div>
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox LgInputField' style={{ width: '700px' }}>
                        <div className='hrBox' >
                            <h3>{sideNavAccordian?.productionEntry}</h3>
                        </div>
                        <div className='inputBoxLists accordionLists'>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Production Details</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='ItemInwardInputBox'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <div className='inputBox'>
                                                        <label>No <span>*</span></label>
                                                        <input type="text" className=''
                                                            name='pid'
                                                            value={productionDet?.pid}
                                                            disabled
                                                            style={{ width: "100%" }} placeholder='Code...'></input>
                                                        {errors.pid && <p style={{ color: 'red' }}>{errors.pid}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Date <span>*</span></label>
                                                        <input type="date" style={{ width: '100%' }}
                                                            name="proddate"
                                                            onChange={(e) => setProductionDet({ ...productionDet, proddate: e.target.value })}
                                                            value={productionDet.proddate = productionDet?.proddate ?? new Date().toISOString().substr(0, 10)}
                                                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                        ></input>
                                                        {errors.proddate && <p style={{ color: 'red' }}>{errors.proddate}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Team <span>*</span></label>
                                                        <AutoComplete
                                                            value={loadPackingPlanItems?.length &&
                                                                loadPackingPlanItems?.find(obj => obj?.Teamid === productionDet?.teamid)?.TeamName}
                                                            clsName='autoComplete full-width'
                                                            placeholder="Select Team.."
                                                            emitOption={(val) => handleAutoCompletes('teamid', val)}
                                                            options={removeDuplicates(loadPackingPlanItems, 'Teamid')}
                                                            getOptionLabel={'TeamName'}
                                                        />
                                                        {errors.teamid && <p style={{ color: 'red' }}>{errors.teamid}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Machine <span>*</span></label>
                                                        <AutoComplete
                                                            value={loadPackingPlanItems?.length > 0 ?
                                                                loadPackingPlanItems?.find(obj => obj?.Machineid === productionDet?.machineid)?.MachineName : ''}
                                                            clsName='autoComplete full-width'
                                                            placeholder="Select Machine.."
                                                            disabled={productionDet?.teamid === 0}
                                                            emitOption={(val) => handleAutoCompletes('machineid', val)}
                                                            options={removeDuplicates(loadPackingPlanItems, 'Machineid')?.filter((machine) => machine?.Teamid === productionDet?.teamid)}
                                                            getOptionLabel='MachineName'
                                                        />
                                                        {errors.machineid && <p style={{ color: 'red' }}>{errors.machineid}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='inputBox'>
                                                        <label>Select Packing Plan No<span>*</span></label>
                                                        <AutoComplete
                                                            value={productionEntryDet?.packingid}
                                                            clsName='autoComplete full-width'
                                                            placeholder="Select Item.."
                                                            disabled={productionDet?.teamid === 0 || productionDet?.machineid === 0}
                                                            emitOption={(val) => handleAutoCompletes('packingid', val)}
                                                            options={removeDuplicates(loadPackingPlanItems, 'packingid')?.filter((item) => item?.Machineid == productionDet?.machineid)}
                                                            getOptionLabel='packingid'
                                                        />
                                                        {errors.packingid && <p style={{ color: 'red' }}>{errors.packingid}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='inputBox'>
                                                        <label>Item Name <span>*</span></label>
                                                        <AutoComplete
                                                            value={productionEntryDet?.itemname}
                                                            clsName='autoComplete full-width'
                                                            placeholder="Select Item.."
                                                            disabled={productionEntryDet?.packingid === 0}
                                                            emitOption={(val) => handleAutoCompletes('itemid', val)}
                                                            //    options={removeDuplicates(loadPackingPlanItems, 'itemname')}
                                                            options={loadPackingPlanItems?.filter((item) => item?.packingid == productionEntryDet?.packingid)}
                                                            getOptionLabel='itemname'
                                                        />
                                                        {errors.itemid && <p style={{ color: 'red' }}>{errors.itemid}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Barcode <span>*</span></label>
                                                        <AutoComplete
                                                            value={productionEntryDet?.barcode}
                                                            clsName='autoComplete full-width'
                                                            placeholder="Select Item.."
                                                            disabled={productionEntryDet?.itemid === 0}
                                                            emitOption={(val) => handleAutoCompletes('barcode', val)}
                                                            options={loadPackingPlanItems?.length ?
                                                                loadPackingPlanItems?.filter((each) => each?.packingid == productionEntryDet?.packingid && each?.Machineid == productionDet?.machineid && each?.Teamid === productionDet?.teamid && each?.Productid === productionEntryDet?.itemid) : []}
                                                            getOptionLabel='barcode'
                                                        />
                                                        {errors.batchno && <p style={{ color: 'red' }}>{errors.batchno}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Batch No <span>*</span></label>
                                                        <AutoComplete
                                                            value={productionEntryDet?.batchno}
                                                            clsName='autoComplete full-width'
                                                            placeholder="Select Item.."
                                                            disabled={productionEntryDet?.itemid === 0}
                                                            emitOption={(val) => handleAutoCompletes('batchno', val)}
                                                            options={loadPackingPlanItems?.length ?
                                                                loadPackingPlanItems?.filter((each) => each?.packingid == productionEntryDet?.packingid && each?.Machineid == productionDet?.machineid && each?.Teamid === productionDet?.teamid && each?.Productid === productionEntryDet?.itemid && each?.barcode === productionEntryDet?.barcode) : []}
                                                            getOptionLabel='Batchno'
                                                        />
                                                        {errors.batchno && <p style={{ color: 'red' }}>{errors.batchno}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                                <div className='col-md-4'>
                                                    <div className='inputBox'>
                                                        <label>Balance Qty <span>*</span></label>
                                                        <input type="text" style={{ width: '100%' }}
                                                            name="balQty"
                                                            disabled
                                                            value={Number(productionEntryDet?.balQty! ?? 0) - Number(productionEntryDet?.qty ?? 0)}
                                                            placeholder="Select Batch No.."
                                                        ></input>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='inputBox'>
                                                        <label>MRP <span>*</span></label>
                                                        <input type="text" style={{ width: '100%' }}
                                                            name="Mrprate"
                                                            disabled
                                                            value={productionEntryDet?.Mrprate}
                                                            placeholder="Select Batch No.."
                                                        ></input>
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='inputBox'>
                                                        <label>Qty <span>*</span></label>
                                                        <input type="text" className=''
                                                            name='qty'
                                                            value={productionEntryDet?.qty}
                                                            onChange={handleQtyChange}
                                                            style={{ width: "100%" }} placeholder='Qty..'></input>
                                                        {errors.qty && <p style={{ color: 'red' }}>{errors.qty}</p>}
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                                <div className="col-md-4">
                                                    <button className='dfBtn' type='submit' onClick={handleEntryAdd}>Add</button>
                                                </div>
                                            </div>
                                            <div className='vertical-space-20'></div>
                                            <div className="tableListDetails">
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                            <th scope="col">S.No</th>
                                                                <th scope="col">Item</th>
                                                                <th scope="col">Barcode</th>
                                                                <th scope="col">Batch No</th>
                                                                <th scope="col">Qty</th>
                                                                <th scope="col">MRP</th>
                                                                <th scope='col'></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {productionDet?.productionentrydetails?.length ?
                                                                productionDet?.productionentrydetails?.map((itemsObj, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{index + 1}</td>
                                                                            <td>{loadItemMasters?.find((item) => item?.itemid === itemsObj?.itemid)?.itemName}</td>
                                                                            <td>{itemsObj?.barcode} </td>
                                                                            <td>{itemsObj?.batchno} </td>
                                                                            <td>{itemsObj?.qty}</td>
                                                                            <td>{itemsObj?.Mrprate} </td>
                                                                            <td><button className='delete' onClick={() => handleRemove(index, 'Entry')}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                                                        </tr>
                                                                    )
                                                                }) :
                                                                <div>
                                                                    No data
                                                                </div>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {errors.lengthnon && <p style={{ color: 'red' }}>{errors.lengthnon}</p>}
                                            </div>
                                            <div className='vertical-space-20'></div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Return Details</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='ItemInwardInputBox'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    {loadPackingDistributionItems?.length > 0 && <div className='inputBox'>
                                                        <label>Item </label>
                                                        <AutoComplete
                                                            clsName='autoComplete full-width'
                                                            value={loadPackingDistributionItems?.find(obj => obj?.PackingItemid === productionWastedDet?.itemid)?.itemname ?? ""}
                                                            placeholder="Select Item.."
                                                            emitOption={(val) => handleAutoComplete('itemid', val)}
                                                            options={removeDuplicates(loadPackingDistributionItems, 'PackingItemid')}
                                                            getOptionLabel='itemname'
                                                        />
                                                        {errors.itemid && <p style={{ color: 'red' }}>{errors.itemid}</p>}
                                                    </div>}
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Batch No </label>
                                                        <AutoComplete
                                                            clsName='autoComplete full-width'
                                                            value={productionWastedDet?.batchno ?? ""}
                                                            placeholder="Select Batch No.."
                                                            emitOption={(val) => handleAutoComplete('batchno', val)}
                                                            options={loadPackingDistributionItems?.length ?
                                                                removeDuplicates(loadPackingDistributionItems?.filter((each) => each?.PackingItemid == productionWastedDet?.itemid), 'Batchno') : []}
                                                            getOptionLabel='Batchno'
                                                        />
                                                        {errors.batchno && <p style={{ color: 'red' }}>{errors.batchno}</p>}
                                                    </div>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>Wastage </label>
                                                        <input type="number" className=''
                                                            name='wastage'
                                                            value={productionWastedDet?.wastage}
                                                            onChange={(e) => setProductionWastedDet({ ...productionWastedDet, wastage: Number(e.target.value) })}
                                                            style={{ width: "100%" }} placeholder='Wastage...'></input>
                                                        {errors.wastage && <p style={{ color: 'red' }}>{errors.wastage}</p>}
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='inputBox'>
                                                        <label>W.Remarks </label>
                                                        <input type="text" className=''
                                                            name='wRemarks'
                                                            value={productionWastedDet?.wRemarks}
                                                            onChange={(e) => setProductionWastedDet({ ...productionWastedDet, wRemarks: e.target.value })}
                                                            style={{ width: "100%" }} placeholder='W.Remarks...'></input>
                                                        {errors.wRemarks && <p style={{ color: 'red' }}>{errors.wRemarks}</p>}
                                                    </div>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                                <div className='col-md-5'>
                                                    <div className='inputBox'>
                                                        <label>Return </label>
                                                        <input type="number" className=''
                                                            name='returnQty'
                                                            value={productionWastedDet?.returnQty}
                                                            onChange={(e) => setProductionWastedDet({ ...productionWastedDet, returnQty: Number(e.target.value) })}
                                                            style={{ width: "100%" }} placeholder='Return...'></input>
                                                        {errors.returnQty && <p style={{ color: 'red' }}>{errors.returnQty}</p>}
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <div className='inputBox'>
                                                        <label>R.Remarks </label>
                                                        <input type="text" className=''
                                                            name='rremarks'
                                                            value={productionWastedDet?.rremarks}
                                                            onChange={(e) => setProductionWastedDet({ ...productionWastedDet, rremarks: (e.target.value) })}
                                                            style={{ width: "100%" }} placeholder='R.Remarks...'></input>
                                                        {errors.rremarks && <p style={{ color: 'red' }}>{errors.rremarks}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <button className='dfBtn' type='submit' onClick={handleWastedAdd}>Add</button>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='vertical-space-20'></div>
                                            <div className="tableListDetails">
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Item</th>
                                                                {/* <th scope="col">Qty</th> */}
                                                                <th scope="col">Wastage</th>
                                                                <th scope="col">W.Remarks</th>
                                                                <th scope="col">Return Qty</th>
                                                                <th scope="col">R.Remarks</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {productionDet?.productionwastedetails?.length ? productionDet?.productionwastedetails?.map((itemsObj, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{loadItemMasters?.find((item) => item?.itemid === itemsObj?.itemid)?.itemName}</td>
                                                                        {/* <td>{itemsObj?.qty}</td> */}
                                                                        <td>{itemsObj?.wastage?.toFixed(3)}</td>
                                                                        <td>{itemsObj?.wRemarks}</td>
                                                                        <td>{itemsObj?.returnQty?.toFixed(3)}</td>
                                                                        <td>{itemsObj?.rremarks}</td>
                                                                        {/* <td>{itemsObj?.batchno}</td> */}
                                                                        <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit'
                                                                            onClick={() => handleRemove(index, 'Waste')}>Remove</button></td>

                                                                    </tr>
                                                                )
                                                            }) :
                                                                <div>
                                                                    No data
                                                                </div>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {errors.lengthnon && <p style={{ color: 'red' }}>{errors.lengthnon}</p>}
                                            </div>
                                            <div className='vertical-space-20'></div>

                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className='inputBoxLists pd-b-0'>
                            <div className='LgInputListsBox mr-left-0'>
                                <div className='btnBox'>
                                    <button className='secondaryBtn' onClick={handleDetails}>List</button>
                                    <button className='dfBtn' type='submit' onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>

                        <Modal show={isSuccessModal} onHide={handleDetails} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/checked.png')} />
                                    </div>
                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleDetails}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/warning.png')} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setFailureModal(false)}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </div>
                </div >

            </>
        )
    }
})

export default ProducationEntry
