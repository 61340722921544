import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useParams } from 'react-router-dom'
import { Modal, Spinner, Button, Image } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import useStores from '../../hooks';
import { IAssignFreeItem } from './models';
import assignFreeItemValidation from './validation';
import moment from 'moment';
import AutoComplete from '../../common/shared/autoComplete';
import { handleFindToDate } from '../../common/shared/utils';

const AssignFreeItem = observer((): JSX.Element => {
  const [option, setOption] = useState<any>({});
  const [isbatchNo, setBatchNo] = useState(false);
  const [isSaleQty, setSaleQty] = useState(false);
  const [sameItem, setSameItem] = useState(false);
  const [itemOptions, setItemOptions] = useState<any>({});
  const [isOtherItem, setOtherItem] = useState(false);
  const employeename = Cookies.get('employee_name');
  const { supplierMasterStore, assignFreeItemStore } = useStores();
  const [isLoading, setLoading] = useState(true);
  const [assignFreeItem, setAssignFreeItem] = useState<IAssignFreeItem>({})
  const [otherItemBatchList, setOtherItemBatches] = useState<any[]>([])
  const [isSpinner, setSpinner] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const navigate = useNavigate();
  const { itemfreeId } = useParams();
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  console.log(option, "optionaaa");

  var radio_props = [{ val: "Batch No", key: 'batchNo' }, { val: "Sale Qty", key: 'saleqty' }];
  var radio_props1 = [
    { val: "Same Item", key: 'sameitem' }, { val: "Other Item", key: 'otheritem' }];

  const handleItemDetails = (val) => {
    setAssignFreeItem({ ...assignFreeItem, itemId: val.itemId, itemName: val.itemName });
  }

  const handleBatch = (name, event, val) => {
    if (name === "otherBatchId" && val != null) {
      setAssignFreeItem({ ...assignFreeItem, otherBarcode: val?.barcode, otherBatchNo: val.batchNo });
    } else {
      setAssignFreeItem({ ...assignFreeItem, batchId: val.batchId, batchNo: val.batchNo });
    }
  }

  const handleOtherItemDetails = async (val, itemId) => {
    setSpinner(true);
    setAssignFreeItem({ ...assignFreeItem, otherItemId: val.itemId });
    const otherItemBatchNo = await assignFreeItemStore.getBatchNos(val.itemId);
    setOtherItemBatches(otherItemBatchNo)
    setSpinner(false);
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setAssignFreeItem({ ...assignFreeItem, [name]: value });
  }
  const handleItemRadioChange = (event) => {
    const { name, value } = event.target;
    setItemOptions({ ...itemOptions, [name]: value })
    if (value === 'sameitem') {
      setSameItem(true);
      setOtherItem(false);
      setAssignFreeItem({ ...assignFreeItem, isSame: true, isOtherItem: false });
    }
    if (value === 'otheritem') {
      if (assignFreeItem.isBatch) {
        setOtherItem(true);
      }
      else {
        setOtherItem(false);
      }
      setSameItem(false);
      setOtherItem(true);
      setAssignFreeItem({ ...assignFreeItem, isSame: false, isOtherItem: true });
    }

  }
  const handleRadioChange = async (event) => {

    const { name, value } = event.target;

    console.log(name, value, "commingnameamdvsalue");

    setOption({ ...option, [name]: value })
    if (value === 'batchNo') {
      if (assignFreeItem.isOtherItem) {
        setOtherItem(true);
      }
      else {
        setOtherItem(false);
      }
      setSpinner(true);
      setBatchNo(true);
      setSaleQty(false);
      setSameItem(false);

      await assignFreeItemStore.getBatchNos(assignFreeItem.itemId);
      setSpinner(false);
      setAssignFreeItem({ ...assignFreeItem, isBatch: true, isSale: false });
    }

    if (value === 'saleqty') {
      setBatchNo(false);
      setSaleQty(true);
      setOtherItem(false);
      setAssignFreeItem({ ...assignFreeItem, isBatch: false, isSale: true });
    }


  }
  const SaveAssignFreeItem = async (e) => {
    e.preventDefault();
    let error: any = {};
    error = assignFreeItemValidation(assignFreeItem, option, itemOptions);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      setLoading(true);
      if (sameItem) {
        assignFreeItem.otherBarcode = '';
        assignFreeItem.otherBatchNo = '';
      }
      assignFreeItem.assignedBy = Number(Cookies.get('employeeId'));
      assignFreeItem.otherBarcode = assignFreeItem.otherBarcode ?? "";
      assignFreeItem.otherBatchNo = assignFreeItem.otherBatchNo ?? "";
      assignFreeItem.freeQty = Number(assignFreeItem.freeQty);
      assignFreeItem.entryQty = Number(assignFreeItem.entryQty) ? Number(assignFreeItem.entryQty) : 0;
      assignFreeItem.effectiveFrom = moment(assignFreeItem.effectiveFrom).format('YYYY-MM-DD hh:mm A');
      assignFreeItem.effectiveTo = moment(assignFreeItem.effectiveTo).format('YYYY-MM-DD hh:mm A');
      const status = await assignFreeItemStore.SaveAssignFreeItem(assignFreeItem);

      if (status === "Success") {
        setLoading(false);
        setSuccessModal(true)
      }
      else {
        setLoading(false);
        setFailureModal(true);
      }
    }
  }
  function mapAssignFreeItem(assignFreeItemDetail) {
    console.log(assignFreeItemDetail, "assignFreeItemDetailaaa");
    assignFreeItem.assignFreeId = assignFreeItemDetail.assignFreeId;
    assignFreeItem.assignDate = moment(assignFreeItemDetail.assigneddate).format('YYYY-MM-DD');
    assignFreeItem.batchId = assignFreeItemDetail.batchId;
    assignFreeItem.batchNo = assignFreeItemDetail.batchNo;
    assignFreeItem.effectiveFrom = moment(assignFreeItemDetail.efffdate).format('YYYY-MM-DD');
    assignFreeItem.effectiveTo = moment(assignFreeItemDetail.efftdate).format('YYYY-MM-DD');
    assignFreeItem.entryQty = assignFreeItemDetail.entryQty;
    assignFreeItem.freeQty = assignFreeItemDetail.freeQty;
    assignFreeItem.isBatch = assignFreeItemDetail.isBatch;
    assignFreeItem.isOtherItem = assignFreeItemDetail.isOtherItem;
    assignFreeItem.isSale = assignFreeItemDetail.isSale;
    assignFreeItem.isSame = assignFreeItemDetail.isSame;
    assignFreeItem.itemId = assignFreeItemDetail.itemId;
    assignFreeItem.itemName = assignFreeItemDetail.itemName;
    assignFreeItem.otherBarcode = assignFreeItemDetail.otherBarcode;
    assignFreeItem.otherBatchNo = assignFreeItemDetail.otherBatchNo;
    assignFreeItem.otherItemId = assignFreeItemDetail.otherItemId;
    setAssignFreeItem(assignFreeItem);
    setOption({"options": assignFreeItem.isSale === true ? "saleqty" : "batchNo"})
  }
  async function prefetchData() {
    setLoading(false);
    if (itemfreeId !== undefined) {
      await assignFreeItemStore.getAssignFreeItemDetail(itemfreeId);
      mapAssignFreeItem(assignFreeItemStore.assignFreeItemDetail)
    } else {
      await assignFreeItemStore.getEntryNo('AssignFreeItem');
    }
    await Promise.all([assignFreeItemStore.getItems(), assignFreeItemStore.getFreeItems()])
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    navigate('/Admin/AssignFreeItemList');
  }

  const handleCancel = (e) => {
    navigate('/Admin/AssignFreeItemList');
  }
  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }


  // const isCurrentPage = useRef(true)
  // useEffect(() => {
  //   if (isCurrentPage.current) {
  //     prefetchData();
  //     isCurrentPage.current = false;
  //   }
  //   return () => { }
  // }, [])

  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Assign_free_item';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              prefetchData();

              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/AssignFreeItemList');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

    return (
      <div className='container'>
        {
          isLoading ?
            <div className='SpinnerBox'>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div> :
            <>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='hrBox'>
                  <h3>Assign Free Item </h3>
                </div>
                <div className='inputBoxLists pd-b-0'>
                  <div className='LgInputListsBox mr-left-0'>
                    <div className='row'>
                      {
                        isSpinner ?
                          <div className='SpinnerBox'>
                            <Spinner animation="grow" size="sm" />
                          </div> : null
                      }
                      <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>Entry No <span>*</span></label>
                          {
                            assignFreeItem.assignFreeId ?
                              <input type="text" value={assignFreeItem.assignFreeId} disabled className='' style={{ width: "100%" }} placeholder='Receipt No..'></input>
                              :
                              <input type="text" value={assignFreeItem.assignFreeId = assignFreeItemStore.entryNo.entryNo !== 0 ? assignFreeItemStore.entryNo.entryNo + 1 : 1} disabled className='' style={{ width: "100%" }} placeholder='Receipt No..'></input>
                          }

                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>Date <span>*</span></label>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="DD-MMM-YYYY"
                            onChange={handleChangeInput}
                            name='assignDate'
                            value={assignFreeItem.assignDate = assignFreeItem.assignDate ?? new Date().toISOString().substr(0, 10)}
                            data-date="" data-date-format="DD MMMM YYYY">
                          </input>
                          {errors.assignDate && <p style={{ color: 'red' }}>{errors.assignDate}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-9'>
                        <div className='inputBox'>
                          <label>Item Name  <span>*</span></label>
                          <AutoComplete
                            placeholder='Enter Item Name'
                            emitOption={(option) => handleItemDetails(option)}
                            getOptionLabel={'itemName'}
                            value={assignFreeItem?.itemName}
                            options={assignFreeItemStore.Items}
                          />
                          {errors.itemId && <p style={{ color: 'red' }}>{errors.itemId}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-3'></div>
                      {radio_props.map((val, key) =>
                        <div className='col-md-3' >
                          <div className='inputBox'>
                            <label>{val.val}</label>
                            <input
                              type='radio'
                              className=''
                              onChange={handleRadioChange}
                              value={val.key}
                              name='options'>
                            </input>&nbsp;
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                      )}
                      {errors.options && <p style={{ color: 'red' }}>{errors.options}</p>}
                      {radio_props1.map((val, key) =>
                        <div className='col-md-3' >
                          <div className='inputBox'>
                            <label>{val.val}</label>
                            <input
                              type='radio'
                              className=''
                              onChange={handleItemRadioChange}
                              value={val.key}
                              name='itemoptions'>
                            </input>&nbsp;
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                      )}
                      {errors.itemOptions && <p style={{ color: 'red' }}>{errors.itemOptions}</p>}
                      {(isbatchNo && assignFreeItemStore?.batchNos?.length > 0) ?
                        <>
                          <div className='col-md-9'>
                            <div className='inputBox'>
                              <label>Batch No  <span>*</span></label>
                              <Autocomplete size="small"
                                disablePortal
                                disabled={!assignFreeItemStore?.batchNos?.length}
                                id="combo-box-demo"
                                options={assignFreeItemStore?.batchNos?.filter((each) => each?.batchQty !== 0)}
                                getOptionLabel={(option: any) => option.batchNo}
                                onChange={(event, val) => handleBatch('batchId', event, val)}
                                renderInput={(params: any) =>
                                  <TextField  {...params} style={{ width: '100%' }}
                                    id="outlined-size-small"
                                    color='info'
                                    size="small"
                                    type="text"
                                    placeholder='Select Batch..'
                                    name='batchId'

                                  />}
                              />
                            </div>
                            {errors.batchId && <p style={{ color: 'red' }}>{errors.batchId}</p>}
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-3'></div>
                        </> : null
                      }
                      {isSaleQty ?
                        <>
                          <div className='col-md-3'>
                            <div className='inputBox'>
                              <label>Entry Qty <span>*</span></label>
                              <input type="text" className='' style={{ width: "100%" }}
                                name='entryQty'
                                onChange={handleChangeInput} value={assignFreeItem.entryQty}
                                placeholder='Entry Qty..'></input>
                              {errors.entryQty && <p style={{ color: 'red' }}>{errors.entryQty}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-9'></div>
                        </> : null
                      }


                      {
                        isOtherItem ?
                          <> <div className='col-md-9'>
                            <div className='inputBox'>
                              <label>Other Item Name  <span>*</span></label>
                              <Autocomplete size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={assignFreeItemStore.freeItems}
                                getOptionLabel={(option: any) => option.itemName}
                                onChange={(event, val) => handleOtherItemDetails(val, '')}
                                loading={true}
                                renderInput={(params: any) =>
                                  <TextField  {...params} style={{ width: '100%' }}
                                    id="outlined-size-small"
                                    color='info'
                                    size="small"
                                    type="text"
                                    placeholder='Select Other Item..'
                                    name='otherItemId'

                                  />}
                              />
                              {errors.otherItemId && <p style={{ color: 'red' }}>{errors.otherItemId}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                            <div className='col-md-3'></div>
                          </>
                          : null
                      }

                      {isOtherItem && otherItemBatchList?.length > 0 ?
                        <>
                          <div className='col-md-9'>
                            <div className='inputBox'>
                              <label>Batch No  <span>*</span></label>
                              <Autocomplete size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={otherItemBatchList}
                                getOptionLabel={(option: any) => option.batchNo}
                                onChange={(event, val) => handleBatch('otherBatchId', event, val)}
                                renderInput={(params: any) =>
                                  <TextField  {...params} style={{ width: '100%' }}
                                    id="outlined-size-small"
                                    color='info'
                                    size="small"
                                    type="text"
                                    placeholder='Select Batch..'
                                    name='batchId'

                                  />}
                              />
                              {errors.batchId && <p style={{ color: 'red' }}>{errors.batchId}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-3'></div>
                        </> : null
                      }

                      <div className='vertical-space-10'></div>
                      <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>Free Qty <span>*</span></label>
                          <input
                            type="text"
                            className=''
                            name='freeQty'
                            onChange={handleChangeInput}
                            value={assignFreeItem.freeQty}
                            style={{ width: "100%" }}
                            placeholder='Free Qty..'>
                          </input>
                          {errors.freeQty && <p style={{ color: 'red' }}>{errors.freeQty}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>Eff.From Date<span>*</span></label>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="DD-MMM-YYYY"
                            data-date=""
                            name='effectiveFrom'
                            onChange={handleChangeInput}
                            value={assignFreeItem.effectiveFrom = assignFreeItem.effectiveFrom ?? new Date().toISOString().substr(0, 10)}
                            // min={new Date().toISOString().slice(0, 16)}
                            data-date-format="DD MMMM YYYY"></input>
                          {errors.effectiveFrom && <p style={{ color: 'red' }}>{errors.effectiveFrom}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>Eff.To Date <span>*</span> </label>
                          <input
                            type="date"
                            style={{ width: "100%" }}
                            placeholder="DD-MMM-YYYY"
                            data-date=""
                            name='effectiveTo'
                            onChange={handleChangeInput}
                            value={assignFreeItem.effectiveTo}
                            min={handleFindToDate(assignFreeItem.effectiveFrom ? assignFreeItem?.effectiveFrom : new Date(), 'datetime-local')}
                            data-date-format="DD MMMM YYYY"></input>
                          {errors.effectiveTo && <p style={{ color: 'red' }}>{errors.effectiveTo}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Assigned By </label>
                          <input
                            type="text"
                            disabled
                            className=''
                            style={{ width: "100%" }}
                            value={assignFreeItem.assignedBy = Cookies.get('employee_name')}
                            placeholder='Assigned By..'>
                          </input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleCancel}>List</button>
                  <button className='dfBtn' type='submit' onClick={SaveAssignFreeItem}>Submit</button>
                </div>
              </div>
            </>
        }
        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/checked.png')} />
              </div>

              <h4>Succesfully Submitted</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSuccessModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>

              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Failed</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFailureModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
});

export default AssignFreeItem;