
import { IOwnStockAdjustmentModel } from '../model/index';
import apiAgent from '../../../APIAgent';
import { runInAction, observable } from 'mobx';

class ownStockAdjustmentOutletStore {
    @observable stockAdjustmentOutletDetails = new Map();
    @observable stockAdjustmentOutlet = new Map();

    saveownAdjustmentOutlet = async (stockAdjustmentOutlet: IOwnStockAdjustmentModel) => {
        try {
            const status = await apiAgent.stockAdjustmentOutletData.saveOwnStockAdjustmentOutlet(stockAdjustmentOutlet);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    getStockAdjustmentOutlet = async (wid, id) => {
        try {
            const stockAdjustmentoutletData = await apiAgent.stockAdjustmentOutletData.getOwnStockAdjustmentOutlet(wid, id);
            runInAction(() => {
                this.stockAdjustmentOutlet = JSON.parse(stockAdjustmentoutletData);
            })
            return JSON.parse(stockAdjustmentoutletData);
        }
        catch (error) {
            console.log(error);
        }
    }

    deleteStockAdjustmentOutlet = async (wid, id) => {
        try {
            const status = await apiAgent.stockAdjustmentOutletData.deleteOwnStockAdjustmentOutlet(wid, id);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

}
export default ownStockAdjustmentOutletStore;