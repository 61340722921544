
import { IWounuseStockAdjustmentModel } from '../model/index';
import apiAgent from '../../../APIAgent';
import { runInAction, observable } from 'mobx';

class wounuseStockAdjustmentStore {
    @observable OwnuseWarehouseEntryDetails = new Map();
    @observable OwnuseWarehouseEntry = new Map();

    saveOwnuseWarehouse = async (OwnuseWarehouseEntry: IWounuseStockAdjustmentModel) => {
        try {
            debugger
            const status = await apiAgent.stockAdjustmentWarehouseData.saveOwnStockAdjustmentWarehouse(OwnuseWarehouseEntry);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    owngetStockAdjustmentWarehouse = async (wid, id) => {
        try {
            const stockAdjustmentData = await apiAgent.stockAdjustmentWarehouseData.getownStockAdjustmentWarehouse(wid, id);
            runInAction(() => {
                this.OwnuseWarehouseEntry = JSON.parse(stockAdjustmentData);
            })
            return JSON.parse(stockAdjustmentData);
        }
        catch (error) {
            console.log(error);
        }
    }

    deleteStockAdjustmentWarehouse = async (wid, id) => {
        try {
            const status = await apiAgent.stockAdjustmentWarehouseData.deleteownStockAdjustmentWarehouse(wid, id);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }


}
export default wounuseStockAdjustmentStore;