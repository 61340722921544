import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from "react-bootstrap";
import useStores from "../../hooks";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Cookies from 'js-cookie';
import { Autocomplete, TextField, ThemeProvider, createTheme } from '@mui/material';
import { ISalesRtnRegister, ISalesRtnRegisterDetails, ISalesRtnRegisterRequest } from './model';
import ProgressBar from '../../common/shared/progressbar';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form, Modal } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { currentPageDatas } from '../../common/shared/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export interface ExcelSheetData {
  Outlet_Name?: string;
  POS_Id?: number,
  Bill_No?: string;
  Bill_Date?: string;
  Bdate?: string;
  Bill_Type?: string,
  Customer_Type?: string,
  ctype?: string,
  M_Id?: number,
  Member_Name?: string,
  Mobile_No?: string;
  Item_Id?: number,
  Item_Name?: string,
  Categry?: string,
  HSN_Code?: string,
  Barcode?: string,
  Batch_No?: string,
  Remarks?: string,
  Qty?: number,
  Unit_Name?: string,
  Free_Qty?: number,
  Rate?: number,
  Amount?: number,
  Discount_Value?: number,
  Discount_Per?: number,
  CGSTP?: number,
  CGST_Value?: number,
  SGSTP?: number,
  SGST_Value?: number,
  Total_Value?: number,
  posroundoff?: number,
  Invoiceamount?: number,
  Cash_Amount?: number,
  Redeem_Amount?: number,
  Sales_Return?: number,
  Card_Amount?: number,
  QR_Code_Amount?: number,
  Online_Store_Order?: number,
  Online_Bank_TR?: number,
  Employee_Name?: string,
  Create_DateTime?: string,
  offdiscount?: number
}

const SalesReturnRegister = () => {

  const { salesReturnRegisterStore } = useStores();
  const { loadRtnItems } = salesReturnRegisterStore;
  const [isLoading, setLoading] = useState(true);
  const [salesRegisterRequest, setSalesRegisterRequest] = useState<ISalesRtnRegisterRequest>(new ISalesRtnRegisterRequest())
  const [errors, setErrors] = useState<any>({})
  const [localFields, setLocalFields] = useState<any>({})
  const [currentPageData, setCurrentPageData] = useState<any[]>([])
  const { salesRegisterStore, posMasterStore, invoiceStore, commonStore } = useStores();
  const { loadOutlets, loadOutlet } = commonStore;
  const [totalPages, setTotalPage] = useState(1);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setSalesRegisterRequest({ ...salesRegisterRequest, [name]: value })
  }

  const handleSalesRegister = async () => {
    console.log("thisfunctcalltrur");
    console.log(salesRegisterRequest, "salesRegisterRequest");
    // setLoading(true)
    const data = await loadRtnItems(salesRegisterRequest);
    setCurrentPageData(data);
    console.log(data, "dataaaaa");

    setLoading(false)
  }

  const handleOutlets = (name: any, event: object, val: any) => {
    if (val != null) {
      setSalesRegisterRequest({ ...salesRegisterRequest, outletId: val.outletid })
      Cookies.set('outletName', val.outletName);
    } else {
      salesRegisterRequest.outletId = 0;
    }
  }

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(salesRegisterStore?.salesRegisterRequest, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  const exportExcelDataDetails = async (excelDataDetails) => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Results');

    worksheet.pageSetup = {
      fitToPage: true,
      fitToHeight: 1,
      fitToWidth: 1,
      orientation: 'landscape',
      paperSize: 9,
    };

    // Create subtitle strings

    const subtitleStart = `Date From : ${moment(salesRegisterRequest?.startDate).format('DD-MM-YYYY')} `;
    const subtitleEnd = `  To : ${moment(salesRegisterRequest?.endDate).format('DD-MM-YYYY')}`;
    const subtitleCombined = `Report Generated on: ${moment(new Date().toLocaleDateString()).format('DD-MM-YYYY')}`; // Static string with current date



    worksheet.mergeCells('A1:P1');
    const titleCell = worksheet.getCell('A1');
    titleCell.value = "GANGA SUPER MARKET";
    titleCell.font = { size: 17, bold: true };
    titleCell.alignment = { horizontal: 'center', vertical: 'middle' };

    worksheet.mergeCells('A2:P2');
    const subtitleCell = worksheet.getCell('A2');
    subtitleCell.value = "SALES RETURN REGISTER " + subtitleStart + subtitleEnd;
    subtitleCell.font = { size: 13, bold: true };
    subtitleCell.alignment = { horizontal: 'center', vertical: 'middle' };


    worksheet.mergeCells('B5:E5');
    const subtitleRCell = worksheet.getCell('B5');
    subtitleRCell.value = subtitleCombined;
    subtitleRCell.font = { size: 13, bold: true };
    subtitleRCell.alignment = { horizontal: 'left', vertical: 'middle' };

    worksheet.addRow([]);

    const addBorders = (cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    };

    const addBordersToMergedCells = (worksheet, startCell, endCell) => {
      const startRow = parseInt(startCell[1]);
      const endRow = parseInt(endCell[1]);
      const startCol = startCell.charCodeAt(0) - 64;
      const endCol = endCell.charCodeAt(0) - 64;

      for (let row = startRow; row <= endRow; row++) {
        for (let col = startCol; col <= endCol; col++) {
          addBorders(worksheet.getCell(row, col));
        }
      }
    };

    //worksheet.mergeCells('B7:C7');
    const SubHeadCell0 = worksheet.getCell('B7');
    SubHeadCell0.value = "SNo";
    SubHeadCell0.font = { bold: true };
    SubHeadCell0.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'B7', 'B8');

    //worksheet.mergeCells('D7:E7');
    const SubHeadCell2 = worksheet.getCell('C7');
    SubHeadCell2.value = "Outlet Name";
    SubHeadCell2.font = { bold: true };
    SubHeadCell2.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'C7', 'C7');

    //worksheet.mergeCells('F7:G7');
    const SubHeadCell3 = worksheet.getCell('D7');
    SubHeadCell3.value = "SR No";
    SubHeadCell3.font = { bold: true };
    SubHeadCell3.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'D7', 'D7');

    // worksheet.mergeCells('H7:I7');
    const SubHeadCell4 = worksheet.getCell('E7');
    SubHeadCell4.value = "SR Date";
    SubHeadCell4.font = { bold: true };
    SubHeadCell4.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'E7', 'E7');

    // worksheet.mergeCells('J7:K7');
    const SubHeadCell5 = worksheet.getCell('F7');
    SubHeadCell5.value = "POS Voc.No";
    SubHeadCell5.font = { bold: true };
    SubHeadCell5.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'F7', 'F7');

    //worksheet.mergeCells('L7:M7');
    const SubHeadCell6 = worksheet.getCell('G7');
    SubHeadCell6.value = "Voc.Date";
    SubHeadCell6.font = { bold: true };
    SubHeadCell6.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'G7', 'G7');

    //worksheet.mergeCells('N7:O7');
    const SubHeadCell7 = worksheet.getCell('H7');
    SubHeadCell7.value = "Member Name";
    SubHeadCell7.font = { bold: true };
    SubHeadCell7.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'H7', 'H7');

    // worksheet.mergeCells('I7:I7');
    const SubHeadCell8 = worksheet.getCell('I7');
    SubHeadCell8.value = "HSNCode";
    SubHeadCell8.font = { bold: true };
    SubHeadCell8.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'I7', 'I7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell9 = worksheet.getCell('J7');
    SubHeadCell9.value = "Item Name";
    SubHeadCell9.font = { bold: true };
    SubHeadCell9.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'J7', 'J7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell10 = worksheet.getCell('K7');
    SubHeadCell10.value = "Barcode";
    SubHeadCell10.font = { bold: true };
    SubHeadCell10.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'K7', 'K7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell11 = worksheet.getCell('L7');
    SubHeadCell11.value = "Batchno";
    SubHeadCell11.font = { bold: true };
    SubHeadCell11.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'L7', 'L7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell12 = worksheet.getCell('M7');
    SubHeadCell12.value = "Qty";
    SubHeadCell12.font = { bold: true };
    SubHeadCell12.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'M7', 'M7');

    const SubHeadCell121 = worksheet.getCell('N7');
    SubHeadCell121.value = "Rate";
    SubHeadCell121.font = { bold: true };
    SubHeadCell121.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'N7', 'N7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell13 = worksheet.getCell('O7');
    SubHeadCell13.value = "Amount";
    SubHeadCell13.font = { bold: true };
    SubHeadCell13.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'O7', 'O7');

    const SubHeadCell131 = worksheet.getCell('P7');
    SubHeadCell131.value = "Discount Value";
    SubHeadCell131.font = { bold: true };
    SubHeadCell131.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'P7', 'P7');

    const SubHeadCell14 = worksheet.getCell('Q7');
    SubHeadCell14.value = "CGST Value";
    SubHeadCell14.font = { bold: true };
    SubHeadCell14.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'Q7', 'Q7');

    const SubHeadCell15 = worksheet.getCell('R7');
    SubHeadCell15.value = "SGST Value";
    SubHeadCell15.font = { bold: true };
    SubHeadCell15.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'R7', 'R7');

    const SubHeadCell16 = worksheet.getCell('S7');
    SubHeadCell16.value = "Total Value";
    SubHeadCell16.font = { bold: true };
    SubHeadCell16.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'S7', 'S7');
 
    const SubHeadCell17 = worksheet.getCell('T7');
    SubHeadCell17.value = "Total Discount Value";
    SubHeadCell17.font = { bold: true };
    SubHeadCell17.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'T7', 'T7');
 
    const SubHeadCell18 = worksheet.getCell('U7');
    SubHeadCell18.value = "Total Tax Value";
    SubHeadCell18.font = { bold: true };
    SubHeadCell18.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'U7', 'U7');

    const SubHeadCell19 = worksheet.getCell('V7');
    SubHeadCell19.value = "Total Return Value";
    SubHeadCell19.font = { bold: true };
    SubHeadCell19.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'V7', 'V7');

    const SubHeadCell20 = worksheet.getCell('W7');
    SubHeadCell20.value = "Reound off";
    SubHeadCell20.font = { bold: true };
    SubHeadCell20.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'W7', 'W7');

    const SubHeadCell21 = worksheet.getCell('X7');
    SubHeadCell21.value = "Net Return Amount";
    SubHeadCell21.font = { bold: true };
    SubHeadCell21.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'X7', 'X7');

    const SubHeadCell23 = worksheet.getCell('Y7');
    SubHeadCell23.value = "Employee Name";
    SubHeadCell23.font = { bold: true };
    SubHeadCell23.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'Y7', 'Y7');

    const SubHeadCell22 = worksheet.getCell('Z7');
    SubHeadCell22.value = "Created Date";
    SubHeadCell22.font = { bold: true };
    SubHeadCell22.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'Z7', 'Z7');

    const testColumnIndex = 5;
    const weightColumnIndex = 6;
    const widthInInches = 3;
    const widthInChars = widthInInches * 5.1;

     
    excelDataDetails.forEach((datadet, index) => {
      console.log(datadet, 'datadet');

      const rowIndex = index + 8;

      const seqNoCell = worksheet.getCell(rowIndex, 2);
      seqNoCell.value = index + 1;
      seqNoCell.alignment = { horizontal: 'right', vertical: 'middle' };
      addBorders(seqNoCell);

      const OutletnameNumberCell = worksheet.getCell(rowIndex, 3);
      OutletnameNumberCell.value = datadet.Outletname;
      OutletnameNumberCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(3).width = 30;
      addBorders(OutletnameNumberCell);

      const BillnoNumberCell = worksheet.getCell(rowIndex, 4);
      BillnoNumberCell.value = datadet.SRNo;
      BillnoNumberCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(4).width = 15;
      addBorders(BillnoNumberCell);

      const BilldateNumberCell = worksheet.getCell(rowIndex, 5);
      BilldateNumberCell.value = datadet.srdate;
      BilldateNumberCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(5).width = 15;
      addBorders(BilldateNumberCell);

      const BilltypeNumberCell = worksheet.getCell(rowIndex, 6);
      BilltypeNumberCell.value = datadet.Billno;
      BilltypeNumberCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(6).width = 15;
      addBorders(BilltypeNumberCell);

      const Customer_TypeCell = worksheet.getCell(rowIndex, 7);
      Customer_TypeCell.value = datadet.vocdate;
      Customer_TypeCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(7).width = 15;
      addBorders(Customer_TypeCell);

      const midCell = worksheet.getCell(rowIndex, 8);
      midCell.value = datadet.membername;
      midCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(8).width = 40;
      addBorders(midCell);

      const membernameCell = worksheet.getCell(rowIndex, 9);
      membernameCell.value = datadet.Hsncode;
      membernameCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(9).width = 15;
      addBorders(membernameCell);

      const MobilenoCell = worksheet.getCell(rowIndex, 10);
      MobilenoCell.value = datadet.itemname;
      MobilenoCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(10).width = 60;
      addBorders(MobilenoCell);

      const itemidCell = worksheet.getCell(rowIndex, 11);
      itemidCell.value = datadet.Barcode;
      itemidCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(11).width = 20;
      addBorders(itemidCell);

      const itemnameCell = worksheet.getCell(rowIndex, 12);
      itemnameCell.value = datadet.batch_no;
      itemnameCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(12).width = 20;
      addBorders(itemnameCell);

      const CategoryCell = worksheet.getCell(rowIndex, 13);
      CategoryCell.value = datadet.Qty;
      CategoryCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(13).width = 10;
      addBorders(CategoryCell);

      const HSNCodeCell1 = worksheet.getCell(rowIndex, 14);
      HSNCodeCell1.value = datadet.rate;
      HSNCodeCell1.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(14).width = 10;
      addBorders(HSNCodeCell1);

      const HSNCodeCell = worksheet.getCell(rowIndex, 15);
      HSNCodeCell.value = datadet.Amount;
      HSNCodeCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(15).width = 10;
      addBorders(HSNCodeCell);

      const Taxtotal = worksheet.getCell(rowIndex, 16);
      Taxtotal.value = datadet.Discountvalue;
      Taxtotal.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(16).width = 15;
      addBorders(Taxtotal);

      const BarcodeCell = worksheet.getCell(rowIndex, 17);
      BarcodeCell.value = datadet.CGSTValue;
      BarcodeCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(17).width = 15;
      addBorders(BarcodeCell);

      const batch_noCell1 = worksheet.getCell(rowIndex, 18);
      batch_noCell1.value = datadet.SGSTValue;
      batch_noCell1.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(18).width = 15;
      addBorders(batch_noCell1);

      const batch_noCell2 = worksheet.getCell(rowIndex, 19);
      batch_noCell2.value = (datadet.Totalvalue);
      batch_noCell2.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(19).width = 20;
      addBorders(batch_noCell2);

      const batch_noCell21 = worksheet.getCell(rowIndex, 20);
      batch_noCell21.value = (datadet.Totaldiscount);
      batch_noCell21.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(20).width = 20;
      addBorders(batch_noCell21);

      const batch_noCell22 = worksheet.getCell(rowIndex, 21);
      batch_noCell22.value = (datadet.TotalTaxvalue);
      batch_noCell22.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(21).width = 20;
      addBorders(batch_noCell22);

      const batch_noCell3 = worksheet.getCell(rowIndex,22);
      batch_noCell3.value = (datadet.ReturnAmount);
      batch_noCell3.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(22).width = 20;
      addBorders(batch_noCell3);

      const batch_noCell4 = worksheet.getCell(rowIndex, 23);
      batch_noCell4.value = (datadet.roundoff);
      batch_noCell4.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(23).width = 15;
      addBorders(batch_noCell4);

      const batch_noCell5 = worksheet.getCell(rowIndex, 24);
      batch_noCell5.value = (datadet.Netvalue);
      batch_noCell5.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(24).width = 20;
      addBorders(batch_noCell5);

      const batch_noCell51 = worksheet.getCell(rowIndex, 25);
      batch_noCell51.value = datadet.Employee_Name;
      batch_noCell51.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(25).width = 20;
      addBorders(batch_noCell51);

      const batch_noCell512 = worksheet.getCell(rowIndex, 26);
      batch_noCell512.value = datadet.createdatetime;
      batch_noCell512.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(26).width = 20;
      addBorders(batch_noCell512);
       

      const lastRow = worksheet.lastRow?.number;
      const sumamount1 = `SUM(M7:M${lastRow})`
      const sumamount2 = `SUM(N7:N${lastRow})`
      const sumdiscount2 = `SUM(O7:O${lastRow})`
      const sumcgst3 = `SUM(P7:P${lastRow})`
       const sumcgst4 = `SUM(Q7:Q${lastRow})`
       const sumcgst5 = `SUM(R7:R${lastRow})`
       const sumcgst6 = `SUM(S7:S${lastRow})`
       const sumcgst7 = `SUM(T7:T${lastRow})`
       const sumcgst8 = `SUM(U7:U${lastRow})`
       const sumcgst9 = `SUM(V7:V${lastRow})`
       const sumcgst10 = `SUM(W7:W${lastRow})`
       const sumcgst11 = `SUM(X7:X${lastRow})`

      

      worksheet.getCell(`L${Number(lastRow) + 1}`).value = 'Total';

      worksheet.getCell(`M${Number(lastRow) + 1}`).value = { formula: sumamount1 };
      worksheet.getCell(`N${Number(lastRow) + 1}`).value = { formula: sumamount2 };
      worksheet.getCell(`O${Number(lastRow) + 1}`).value = { formula: sumdiscount2 };
      worksheet.getCell(`P${Number(lastRow) + 1}`).value = { formula: sumcgst3 };
      worksheet.getCell(`Q${Number(lastRow) + 1}`).value = { formula: sumcgst4 };
      worksheet.getCell(`R${Number(lastRow) + 1}`).value = { formula: sumcgst5 };
      worksheet.getCell(`S${Number(lastRow) + 1}`).value = { formula: sumcgst6 };
      worksheet.getCell(`T${Number(lastRow) + 1}`).value = { formula: sumcgst7 };
      worksheet.getCell(`U${Number(lastRow) + 1}`).value = { formula: sumcgst8 };
      worksheet.getCell(`V${Number(lastRow) + 1}`).value = { formula: sumcgst9 };
      worksheet.getCell(`W${Number(lastRow) + 1}`).value = { formula: sumcgst10 };
      worksheet.getCell(`X${Number(lastRow) + 1}`).value = { formula: sumcgst11 };
      

 
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, `Sales_RTN_Register_Report${new Date().toISOString().split('T')[0]}.xlsx`);

  }

  async function createExportExcelObj() {
    const salesRegisterData = await loadRtnItems(salesRegisterRequest);
    console.log(salesRegisterData, 'salesRegisterData');

    exportExcelDataDetails(salesRegisterData)
     
  }

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Sales Return Register", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Sales Register ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async function fetchOultetItems() {
    await loadOutlet();
    salesRegisterRequest.outletId = Number(Cookies.get('outletId'));
    setLoading(false);

  }
  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchOultetItems();
      isCurrentPage.current = false
    }
    return () => { }
  }, []);
  return (
    <>
      {
        isLoading ? <ProgressBar /> :
          <>
            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='hrBox'>
                  <h3>Sales Return Register</h3>
                </div>
                <div className='inputBoxLists'>
                  <div className='ItemInwardInputBox'>
                    <div className='row'>
                      <div className='col-sm-2'>
                        <div className='inputBox'>
                          <label>From Date <span>*</span></label>
                          <input type="date" style={{ width: "80%" }}
                            name="startDate"
                            onChange={handleInputChange}
                            value={salesRegisterRequest?.startDate}
                            placeholder="DD-MMM-YYYY"
                            data-date=""
                            data-date-format="DD MM YYYY">
                          </input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-sm-2'>
                        <div className='inputBox'>
                          <label>To Date <span>*</span></label>
                          <input type="date" style={{ width: "80%" }}
                            name="endDate" onChange={handleInputChange} value={salesRegisterRequest?.endDate}
                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                        </div>
                        <div className='vertical-space-20'></div>
                      </div>
                      <div className='col-sm-3' style={{ marginTop: '17px' }}>
                        <div className='inputBox'>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadOutlets}
                            getOptionLabel={(option: any) => option.outletName}
                            onChange={(event, val) => handleOutlets('outletId', event, val)}
                            value={loadOutlets.find((option) => option.outletName === Cookies.get('outletName'))}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '35ch' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Outlet Name..'
                                name='outletName'
                              />}
                          />
                        </div>
                        {/* <div className='vertical-space-10'></div> */}
                      </div>
                      <div className="col-sm-5">
                        <div className='btnBox' style={{ marginTop: '23px' }}>
                          <button className='secondaryBtn' onClick={() => createExportExcelObj()}
                          >Excel</button>
                          <button className='secondaryBtn' onClick={() => handleSalesRegister()}>Generate</button>
                        </div>
                      </div>
                    </div>
                    <div className='vertical-space-10'></div>
                    <div className='row'>
                      <div className='col-sm-5'>
                        <div className='btnBox'>
                          <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{salesRegisterStore?.outletItemDetails?.length} Records</button>
                        </div>
                      </div>
                      <div className=' col-sm-7' >
                        <Stack spacing={2}>
                          <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                        </Stack>
                      </div>
                    </div>
                    <div className='tableListDetails'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>SR.No</th>
                            <th>SR.Date</th>
                            <th scope="col">POS Vouch No</th>
                            <th scope="col">V.Date</th>
                            <th scope="col">Member </th>
                            <th scope="col">HSNCode </th>
                            <th scope="col">Item Name</th>
                            <th scope="col">Barcode</th>
                            <th scope="col">Batchno</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Return Value</th>
                            <th scope="col">Total Tax Value</th>
                            <th scope='col'>CGST </th>
                            <th scope='col'>SGST</th>
                            <th scope='col'></th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPageData?.length > 0 &&
                            currentPageData?.map((val, key) => {
                              console.log(currentPageData, 'currentPageData>>')
                              return (
                                <tr key={key}>
                                   <td>{val.SRNo}</td>
                                  <td>{moment(val.srdate).format('DD-MMM-YYYY')} </td>
                                  <td>{val.Billno}</td>
                                  <td>{moment(val.vocdate).format('DD-MMM-YYYY')} </td>
                                   <td>{val.membername}</td>
                                  <td>{val.Hsncode} </td>
                                  <td>{val.itemname} </td>
                                  <td>{val.Barcode}</td>
                                  <td>{val.batch_no}</td>
                                  <td>{val.Qty}</td>
                                  <td>{val.ReturnAmount}</td>
                                  <td>{val.TotalTaxvalue}</td>
                                  <td>{val.CGSTValue}</td>
                                  <td>{val.SGSTValue}</td>

                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
      }
    </>

  )
}

export default SalesReturnRegister;