import { IAssignOfferDiscountModel } from "../../../CRM/AssignOfferDiscount/model";


function OwnOutletEntryValidation(assignOfferandDicount, options) {

  console.log(assignOfferandDicount, "assignOfferandDicountaaaa");

  let errors: any = {};

  /*New Code start*/

  if (!assignOfferandDicount.barcode) {
    errors.barcode = "Select Barcode No"
  }

  if (!assignOfferandDicount.batchno) {
    errors.batchno = "Select Batch No"
  }

  if (assignOfferandDicount.lessQty === 0) {
    if (!assignOfferandDicount.addQty) {
      errors.addQty = "Enter Add Qty"
    }
    // if (assignOfferandDicount.batchqty !== 0 && assignOfferandDicount.addQty > assignOfferandDicount.batchqty) {
    //   errors.addQty = "Check Batch Qty"
    // }
  }

  if (assignOfferandDicount.addQty === 0) {
    if (!assignOfferandDicount.lessQty) {
      errors.lessQty = "Enter less Qty"
    }
    if (assignOfferandDicount.batchqty !== 0 && assignOfferandDicount.lessQty > assignOfferandDicount.batchqty) {
      errors.lessQty = "Check Batch Qty"
    }
  }

  if (!assignOfferandDicount.mrp) {
    errors.mrp = "Enter MRP"
  }

  /*New Code End*/

  // if (Object.keys(options).length === 0) {
  //   errors.options = "Select Options";
  // }

  if (options.options === 'products') {
    if (!assignOfferandDicount.assignItemId) {
      errors.assignItemId = "Select Item Name";
    }
  }

  if (options.options === 'category') {
    if (!assignOfferandDicount.assignCategoryId) {
      errors.assignCategoryId = "Enter Item Category";
    }
  }

  // if (!assignOfferandDicount.assignDiscountOfferId) {
  //   errors.assignDiscountOfferId = "Select Discount";
  // }

  // if (!assignOfferandDicount.assignEffectiveFromDate) {
  //   errors.assignEffectiveFromDate = "Select Effect From Date";
  // }

  // if (!assignOfferandDicount.assignEffectiveToDate) {
  //   errors.assignEffectiveToDate = "Select Effect To Date";
  // }

  return errors;
};

export function saveValidation(assignOffers: IAssignOfferDiscountModel) {
  let errors: any = {}
  if (!assignOffers.assignOffetDetails) {
    errors.assignOffetDetails = "Select Effect To Date";
  }

  return errors;
}

export default OwnOutletEntryValidation;