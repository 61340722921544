import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Button, Image, Modal } from 'react-bootstrap';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';

const BarcodeConfigurationDetails = observer((): JSX.Element => {
    const navigate = useNavigate();

    const { barcodeConfigStore, userCreationStore } = useStores();
    const { getWareHouseManager, getBarcodeConfigDetails, DeleteBarcodeConfigDetails } = barcodeConfigStore;
    const [isAssignedOfferDetails, setAssignOfferDiscountModal] = useState(false);
    const [userRights, setRightsObj] = useState<any>({})
    const [BarcodeDetails, setBarcodeDetails] = useState<any[]>([])
    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    async function fetchWareHouseManagerData() {
        setLoading(false);
        const [userRights, configList] = await Promise.all([userCreationStore?.getUserRight('Assign_warehouse_managers'), getBarcodeConfigDetails('0')])
        setFilteredData(configList);
        if (userRights) {
            setRightsObj({ ...userRights })
        }
    }

    const BarcodeDetials = async (tempName: string) => {
        const BarcodeDetails = await getBarcodeConfigDetails(tempName);
        setBarcodeDetails([...BarcodeDetails])
        console.log(BarcodeDetails, 'BarcodeDetails');
        if (BarcodeDetails !== undefined || BarcodeDetails !== null) {
            setAssignOfferDiscountModal(true);
        }
    }

    const handleClose = () => {
        setAssignOfferDiscountModal(false);
    }

    const deleteWarehouseManagerDetials = async (wmId) => {
        setLoading(true);
        const status = await DeleteBarcodeConfigDetails(wmId);
        if (status === 'Success') {
            fetchWareHouseManagerData();
            setLoading(false);
            setSuccessModal(true);
        }
        else {
            setLoading(false);
            setFailureModal(true);
        }
    }
    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        setSuccessModal(false);
        fetchWareHouseManagerData();
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchWareHouseManagerData();
            isCurrentPage.current = false;
        }
        return () => { }
    }, []);

    const handleCreate = () => {
        navigate('/Admin/BarcodeConfiguration');
    }

    useEffect(() => {
        if (filteredData?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(filteredData)
        }
    }, [filteredData])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(filteredData?.slice()?.sort((a, b) => b.wmId - a.wmId), value, 10)
        setTotalPage(currentPageList?.totalPages);
        setCurrentPageData(currentPageList?.currentPageData);
    };
    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container-fluid'>
                            <div className='outletInputField inputFormBox LgInputField'>
                                <div className='vertical-space-20'></div>
                                <SelectSearchCriteria
                                    searchEnable={(bool) => setSearch(bool)}
                                    searchArray={BarcodeDetails}
                                    searchTitles={[{ key: "warehouseName", value: "Warehouse" }, { key: "employeeName", value: "Manager" }]}
                                    emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                                <div className='vertical-space-20'></div>
                                <div className='inputBoxLists'>
                                    <div className='row'>
                                        <div className='col-sm-5'>
                                            <div className='btnBox'>
                                                <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{BarcodeDetails?.length} Records</button>
                                            </div>
                                        </div>
                                        <div className=' col-sm-7' >
                                            <Stack spacing={2}>
                                                <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>
                                <div className='tableListDetails'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope='col'>Sl.No </th>
                                                <th scope='col'>Template Name </th>
                                                <th scope='col'>Filename </th>
                                                <th scope='col'>Details</th>
                                                <th scope='col'>Create </th>
                                                <th scope='col'>Delete </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                                (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                                                    console.log(val, "valasaasasassdsd");
                                                    return (
                                                        <tr key={key}>
                                                            <td>{val.sno}</td>
                                                            <td>{val.TemplateName}</td>
                                                            <td>{val.filename}</td>
                                                            <td><button onClick={() => BarcodeDetials(val.TemplateName)} className='Details'>Details</button></td>
                                                            <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteWarehouseManagerDetials(val.TemplateName)} /></button></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    isAssignedOfferDetails ?
                                        <Modal show={isAssignedOfferDetails} onHide={handleClose} className='PriceHistoryModel'>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Details</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">S.No</th>
                                                                <th scope="col">Barcode Configuration</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                BarcodeDetails?.map((val, key) => {
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{val.sno}</td>
                                                                            <td>{val.description}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Modal.Body>
                                        </Modal> : null
                                }
                                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                    <Modal.Body>
                                        <div className='Details Success'>
                                            <div className='imgBox'>
                                                <Image src={require('../../../gsmecom/images/checked.png')} />
                                            </div>

                                            <h4>Succesfully Deleted</h4>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleSuccessModalClose}>
                                            Ok
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                    <Modal.Body>
                                        <div className='Details Success'>

                                            <div className='imgBox'>
                                                <Image src={require('../../../gsmecom/images/warning.png')} />
                                            </div>
                                            <h4>Failed</h4>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleFailureModalClose}>
                                            Ok
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </>
            }
        </>

    );

});

export default BarcodeConfigurationDetails;
