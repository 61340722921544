import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import moment from 'moment';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from 'react-router-dom';
import { IPackingDistributionSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';


const PackingDistributionDetail = observer((): JSX.Element => {
  const { packingDistributionStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { getPackingDistributionDetails, getPackingDistribution, packingDistributionDetails, getPackingDistributions } = packingDistributionStore;
  const [isLoading, setLoading] = useState(true);
  const [isPackingConversionShow, setPackingConversionModal] = useState(false);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const deletePackingMaster = async (PackingDistributionId) => {
    setLoading(true);
    const status = await packingDistributionStore.deletePackingDistributionMaster(PackingDistributionId);

    if (status === 'Success') {
      fetchPreLoadngData();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const deletePackingDetail = async (packingDistributionDetailId) => {
    setPackingConversionModal(false);
    setLoading(true);
    const status = await packingDistributionStore.deletePackingDistributionDetail(packingDistributionDetailId);

    if (status === 'Success') {
      fetchPreLoadngData();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchPreLoadngData();
  }

  const PackingDistributionDetail = async (packingDistributionId) => {
    const data = await getPackingDistributionDetails(packingDistributionId);
    if (data !== undefined || data !== null) {
      setPackingConversionModal(true);
    }
  }

  const handleClose = () => {
    setPackingConversionModal(false);
  }

  function editPackingDistribution(packingDistributionId) {

    navigate('/Admin/PackingDistribution/' + packingDistributionId);

  }


  async function fetchPreLoadngData() {
    setLoading(false)
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Packing_distribution'), getPackingDistribution()])
    if (userRights) {
      setRightsObj({ ...userRights })
    }
  }

  const isCCurrenPage = useRef(true)

  useEffect(() => {
    if (isCCurrenPage.current) {
      fetchPreLoadngData();
      isCCurrenPage.current = false
    }
    return () => { }
  }, []);

  const handleCreate = () => {
    navigate('/Admin/PackingDistribution');
  }
  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (getPackingDistributions?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(getPackingDistributions)
    }
  }, [getPackingDistributions])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(getPackingDistributions?.slice()?.sort((a, b) => b.packingDistributionId - a.packingDistributionId), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <ProgressBar /> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={getPackingDistributions}
                searchTitles={[{ key: "teamName", value: "Team Name" }, { key: "wareHouseName", value: "Warehouse Name" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{getPackingDistributions?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>Entry No </th>
                      <th scope='col'>Date  </th>
                      <th scope='col'>Team  </th>
                      <th scope='col'>Warehouse  </th>
                      <th scope='col'></th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      ((isSearch ? filteredData : currentPageData))?.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.packingDistributionId}</td>
                            <td>{moment(val.packingDistributionDate).format('DD-MMM-YYYY')}</td>
                            <td>{val.teamName}</td>
                            <td>{val.wareHouseName}</td>
                            <td><button disabled={!userRights?.view} onClick={() => PackingDistributionDetail(val.packingDistributionId)}>Detail</button></td>
                            <td><button disabled={!userRights?.add} className='Add' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit' onClick={() => editPackingDistribution(val.packingDistributionId)} ><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deletePackingMaster(val.packingDistributionId)} /></button></td>
                          </tr>
                        )
                      }
                      )}
                  </tbody>
                </table>
              </div>
            </div>

            <Modal show={isPackingConversionShow} onHide={handleClose} className='PriceHistoryModel'>
              <Modal.Header closeButton>
                <Modal.Title>Detail</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='tableBox'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">Unit Name</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Barcode</th>
                        <th scope="col">Batch No</th>
                        {/* <th scope='col'>Delete </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {packingDistributionDetails?.length > 0 ?
                        packingDistributionDetails?.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{val.packingDistributionitemName}</td>
                              <td>{val.packingDistributionitemUOM}</td>
                              <td>{(val.packingDistributionpackingQty)?.toFixed(8)}</td>
                              <td>{val.packingDistributionbarcode}</td>
                              <td>{val.packingDistributionbatchNo}</td>
                              {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={()=>deletePackingDetail(val.packingDistributionDetailId)}/></button></td> */}
                            </tr>
                          )
                        }) : null
                      }
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }
    </>
  );

});

export default PackingDistributionDetail;
