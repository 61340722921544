            
/* New Code */

import React, { useState } from 'react'
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import barcodeConfigurationValidation from './validation';
import { Accordion, Button, Image, Modal, Spinner } from 'react-bootstrap'

function BarcodeConfiguration() {

    const [gsmCode, setGsmCode] = useState(false);
    const [extraCode, setExtraCode] = useState(false);
    const [itemName, setItemName] = useState(false);
    const [regionalItemName, setRegionalItemName] = useState(false);
    const [category, setCategory] = useState(false);
    const [hsnCode, setHsnCode] = useState(false);
    const [netWeight, setNetWeight] = useState(false);
    const [grossWeight, setGrossWeight] = useState(false);
    const [subCategory, setSubCategory] = useState(false);
    const [brand, setBrand] = useState(false);
    const [mrp, setMrp] = useState(false);
    const [effectiveDate, setEffectiveDate] = useState(false);
    const [purchasePrice, setPurchasePrice] = useState(false);
    const [currentStock, setCurrentStock] = useState(false);
    const [unitOfMeasure, setUnitOfMeasure] = useState(false);
    const [unitType, setUnitType] = useState(false);
    const [barcode, setBarcode] = useState(false);
    const [batchNo, setBatchNo] = useState(false);
    const [file, setfile] = useState<any>();
    const [templateName, setTemplateName] = useState("");
    const navigate = useNavigate();
    const [errors, setErrors] = useState<any>({});
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
        const [isLoading, setLoading] = useState(true);

    const { barcodeConfigStore } = useStores();
    // const { saveBarcodeConfiguration } = barcodeConfigStore;

    const handleChangeview = () => {
        navigate('/Admin/BarcodeConfigurationDetails');
    }

    function templateNameValidation(value) {
        let error: any = {}
        if (!value?.productName) {
            error.templateNmae = 'Enter Template Name'
        }
        return error
    }
    function handleDetails() {
        navigate('/Admin/BarcodeConfigurationDetails');
    }

    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
    }

    const handleSave = async () => {
       
        const fieldNames = {
            gsmCode,
            extraCode,
            itemName,
            regionalItemName,
            category,
            hsnCode,
            netWeight,
            grossWeight,
            subCategory,
            brand,
            mrp,
            effectiveDate,
            purchasePrice,
            currentStock,
            unitOfMeasure,
            unitType,
            barcode,
            batchNo,
            templateName
        }
        let error: any = {};
        error = templateNameValidation(templateName);
        setErrors(error);

        const formData = new FormData();
        formData.append("obj", JSON.stringify(fieldNames));
        formData.append("file", file);

        try {
            const res = await fetch('http://localhost:49882/api/ItemMaster/BarcodeTemplatesave', {
                //  const res =  await fetch('https://api.varthagam.in/api/api/ItemMaster/BarcodeTemplatesave',{                
                method: "POST",
                // Uncomment the headers if needed
                // headers: {   
                //     'Content-Type': 'application/json'
                // },
                body: formData
            });

            console.log(res, 'res123');

            // Check if the response is OK (status in the range 200-299)
            if (res.ok) {
                    setSuccessModal(true);
                    setLoading(false)
                         
            } else {
                setFailureModal(true)
                setLoading(false)
            }
        } catch (error) {
            // Handle any network errors
            console.error("Network error:", error);

        }
    }

    const handleFilechange = (e: React.ChangeEvent<HTMLInputElement>) => {
       
        const selectedFile = e.target.files && e.target.files[0];
        setfile(selectedFile);
    }


    return (
        <div className=''>
            <div className='barcode-config-fields inputFormBox'>
                <div className='hrBox'>
                    <h3>Barcode Configuration</h3>
                </div>
                <div className='row' style={{ margin: "20px" }}>
                    <div className='col-md-4'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setGsmCode(!gsmCode)} type="checkbox" checked={gsmCode} id="gsmCode" />
                            <label className='label-style'>GSM code</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setExtraCode(!extraCode)} type="checkbox" checked={extraCode} id="extraCode" />
                            <label className='label-style'>Extra Code</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setItemName(!itemName)} type="checkbox" checked={itemName} id="itemName" />
                            <label className='label-style'>Item Name</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setRegionalItemName(!regionalItemName)} type="checkbox" checked={regionalItemName} id="regionalItemName" />
                            <label className='label-style'>Regional Item Name</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setCategory(!category)} type="checkbox" checked={category} id="category" />
                            <label className='label-style'>Category</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setHsnCode(!hsnCode)} type="checkbox" checked={hsnCode} id="hsnCode" />
                            <label className='label-style'>HSN Code</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setNetWeight(!netWeight)} type="checkbox" checked={netWeight} id="netWeight" />
                            <label className='label-style'>Net Weight</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setGrossWeight(!grossWeight)} type="checkbox" checked={grossWeight} id="grossWeight" />
                            <label className='label-style'>Gross Weight</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setSubCategory(!subCategory)} type="checkbox" checked={subCategory} id="subCategory" />
                            <label className='label-style'>Sub Category</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setBrand(!brand)} type="checkbox" checked={brand} id="brand" />
                            <label className='label-style'>Brand</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setMrp(!mrp)} type="checkbox" checked={mrp} id="mrp" />
                            <label className='label-style'>MRP</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setEffectiveDate(!effectiveDate)} type="checkbox" checked={effectiveDate} id="effectiveDate" />
                            <label className='label-style'>Effective Date</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setPurchasePrice(!purchasePrice)} type="checkbox" checked={purchasePrice} id="purchasePrice" />
                            <label className='label-style'>Purchase Price</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setCurrentStock(!currentStock)} type="checkbox" checked={currentStock} id="currentStock" />
                            <label className='label-style'>Current Stock</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setUnitOfMeasure(!unitOfMeasure)} type="checkbox" checked={unitOfMeasure} id="unitOfMeasure" />
                            <label className='label-style'>Unit Of Measure</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setUnitType(!unitType)} type="checkbox" checked={unitType} id="unitType" />
                            <label className='label-style'>Unit Type</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setBarcode(!barcode)} type="checkbox" checked={barcode} id="barcode" />
                            <label className='label-style'>Barcode</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='col-md-4 mt-2'>
                        <div className='inputBox'>
                            <input className="form-check-input" onClick={() => setBatchNo(!batchNo)} type="checkbox" checked={batchNo} id="batchNo" />
                            <label className='label-style'>Batch No</label>
                            <div className='vertical-space-10' />
                        </div>
                    </div>

                    <div className='inputBoxLists'>
                        <div className='inputBox'>
                            <label className='label-style'>Template Name</label>
                            <input
                                type='text'
                                autoComplete='nope'
                                className='fullInput'
                                style={{ width: "50%" }}
                                placeholder='Enter Template Name'
                                value={templateName}
                                onChange={(e) => setTemplateName(e.target.value)}
                                name='templateName'
                            />
                            <div className='vertical-space-10' />
                        </div>
                    </div>
                    <div className='inputBoxLists'>
                        <div className='inputBox'>
                            <label className='label-style'>Template Name</label>
                            <input
                                type='file'
                                autoComplete='nope'
                                className='fullInput'
                                style={{ width: "50%" }}
                                onChange={handleFilechange}
                                name='file'
                            />
                            <div className='vertical-space-10' />
                        </div>
                    </div>

                    <div className='btnBox mt-4'>
                        <button className='secondaryBtn' onClick={handleChangeview}>List</button>
                        <button className="dfBtn" type="submit" onClick={() => handleSave()}>
                            Submit
                        </button>
                    </div>
                </div>
                <Modal show={isSuccessModal} onHide={handleDetails} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/checked.png')} />
                                    </div>
                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleDetails}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/warning.png')} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setFailureModal(false)}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                
            </div>
            
        </div>
        
    )
}

export default BarcodeConfiguration;