import React, { useState, useEffect, useRef, } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import AutoComplete from '../../common/shared/autoComplete';
import _ from 'lodash';
import { removeDuplicates } from '../../common/shared/utils';
import { useParams } from 'react-router-dom';
import { IStockAdjustmentDetails, IStockAdjustmentModel } from './model';
// import stockAdjustmentValidation from './validation';
import { Autocomplete, TextField } from '@mui/material';
import Cookies from 'js-cookie';

const LabelGeneration = observer((): JSX.Element => {

  const { commonStore, posMasterStore, BarcodeConfigStore, outwardtoOutletStore, itemMasterStore, supplierMasterStore } = useStores();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [errors, setErrors] = useState<any>({});
  const { loadEmployee, loadManageOfferOrDiscount, loadOutlet, loadOutlets } = commonStore;
  const { loadPOSProductList } = posMasterStore;
  const { getItemMasters, GETItemwiseStockAll } = itemMasterStore;
  const { getBarcodeConfigDetails } = BarcodeConfigStore;

  const [isBatchno, setIsBatchno] = useState(false);
  const [isBarcodeNo, setIsBarcodeNo] = useState(false);
  const [itemMasterList, setItemMasterList] = useState<any[]>([])
  const [batchList, setBatchList] = useState<any[]>([])
  const [barcodeList, setBarcodeList] = useState<any[]>([])
  const [batchBarcodeList, setBatchBarcodeList] = useState<any[]>([])
  const [isLess, setLess] = useState(false);
  const [isAdd, setAdd] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const { stockadjsid } = useParams<{ stockadjsid: string }>();
  const [isReqQtyModal, setReqQtyModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);

  async function fetchPreLoadngData() {
    setLoading(false)
    await Promise.all([loadOutlet()])
    const itemMasterList = await GETItemwiseStockAll(1, 0, '', 1);
    setItemMasterList([...itemMasterList])
     const TemplateList = await getBarcodeConfigDetails('0');
     console.log(TemplateList,'TemplateList999');
  
    if (!stockadjsid) {
      const entryNos = await outwardtoOutletStore.getEntryNo('StockadjOutlet');
      console.log(entryNos, "entryNosssd");
      stockAdjustment.entryNo = entryNos?.entryNo ? Number(entryNos?.entryNo) + 1 : 1;
    }
  }
  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }
  const isInitialRender = useRef(true);
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'StockAdjustment_Outlet';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {

          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchPreLoadngData();

              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/StockAdjustmentList/Outlet');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        } else {
          setLoadModal(true)
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);



  const handleCancel = () => {
    navigate('/Admin/StockAdjustmentList/Outlet');
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    navigate('/Admin/StockAdjustmentList/Outlet');
  }

  const [options, setOptions] = useState<any>({});

  const [stockAdjustmentDetails, setStockAdjustmentDetails] = useState<IStockAdjustmentDetails>(new IStockAdjustmentDetails())
  const [stockAdjustment, setStockAdjustment] = useState<IStockAdjustmentModel>(new IStockAdjustmentModel())

  console.log(stockAdjustment, "stockAdjustmentsss");

  const handleReqQtyModalClose = () => setReqQtyModal(false);

  const handleInputChange = (name) => (event) => {

    const { name, value } = event.target;
    console.log(typeof name, value, "valuecomming");
    if (name === 'altQtyAdd') {
      if (value > 0) {
        setAdd(true)
      } else {
        setAdd(false)
      }
      setStockAdjustment({ ...stockAdjustment, addQty: Number(value), lessQty: Number(0) });
    }
    if (name === 'altQtyLess') {
      if (Number(value) > Number(stockAdjustment?.batchqty)) {
        setReqQtyModal(true);
      }
      else {
        setReqQtyModal(false);
      }

      if (value > 0) {
        setLess(true)
      } else {
        setLess(false)
      }
      setStockAdjustment({ ...stockAdjustment, lessQty: Number(value), addQty: Number(0) });
    }
    if (name === 'MRP') {
      console.log('thisfunctionwork');
      setStockAdjustment({ ...stockAdjustment, mrp: Number(value) });
    }

    if (name === 'altBarcode') {
      setStockAdjustment({ ...stockAdjustment, barcode: value?.toString() });
    }
    if (name === 'altBatchNo') {
      setStockAdjustment({ ...stockAdjustment, batchno: value });
    }
  }


  console.log(stockAdjustment, "stockAdjustmentcon");

  const handleInputChangeDate = (e) => {
    const { name, value } = e.target;
    setStockAdjustment({ ...stockAdjustment, [name]: value });
  }

  async function handleAutoComplete(name, value) {
    console.log(value, 'valuestockAdjustment');

    if (name === "barcode" && value != null) {
      const itemMasterList = await GETItemwiseStockAll(3, value.itemid, value?.Barcode.toString(), 1);
      setBatchList(itemMasterList);
      setBatchBarcodeList(itemMasterList);
      console.log(itemMasterList, "dditemMasterList");
      // const batchList = batchBarcodeList?.filter((batchfil) => batchfil?.Barcode === value?.Barcode);
      // setBatchList(batchList);
      // setBatchBarcodeList(itemMasterList);
      setStockAdjustment({ ...stockAdjustment, [name]: value?.Barcode?.toString() })
    } else if (name === "batchno" && value != null) {
      setStockAdjustment({ ...stockAdjustment, [name]: value?.batchno, mrp: value?.mrp, batchqty: Number(value?.stock) })
    } else if (name === "wid" && value != null) {
      setStockAdjustment({ ...stockAdjustment, [name]: value?.outletid })
    }
  }

  function addOfferToGrid(e: React.FormEvent) {

    if (Number(stockAdjustment?.lessQty) > Number(stockAdjustment?.batchqty)) {
      setReqQtyModal(true);
    }
    else {
      setReqQtyModal(false);
    }

    e.preventDefault();
    let error: any = {}
    error = '';
    //stockAdjustmentValidation(stockAdjustment, options);
    setErrors(error);

    if (!stockAdjustment.stockAdjoutdetails) {
      stockAdjustment.stockAdjoutdetails = [];
    }
    // const isDuplicate = stockAdjustment.stockAdjoutdetails.some(
    //     detail => detail.itemid === stockAdjustment.itemid && detail.barcode === stockAdjustment.barcode && detail.batchno === stockAdjustment.batchno
    // );

    console.log(Object.keys(error), "commingerrorlength");

    if (Object.keys(error)?.length === 0) {
      stockAdjustment.stockAdjoutdetails.push({
        itemid: stockAdjustment.itemid || 0,
        barcode: stockAdjustment.barcode?.toString() || '',
        batchno: stockAdjustment.batchno || '',
        addQty: Number(stockAdjustment.addQty) || 0,
        lessQty: Number(stockAdjustment.lessQty) || 0,
        itemName: stockAdjustment.itemName || '',
        mrp: Number(stockAdjustment.mrp) || 0,
      });
      setStockAdjustment({
        ...stockAdjustment, itemid: 0, addQty: 0, lessQty: 0, itemName: '',
        barcode: '', batchno: '', mrp: 0, batchqty: 0, currentStock: 0
      });
      setLess(false);
      setAdd(false);
      setIsBatchno(false);
      setIsBarcodeNo(false);
      setErrors({});
    }
  }


  function handleRemove(index) {
    stockAdjustment?.stockAdjoutdetails?.splice(index, 1)
    setStockAdjustment(_.cloneDeep(stockAdjustment))
  }

  async function saveStockAdjustmentOutlet(e) {
    let error: any = {}
    // error = saveValidation(assignOffer)
    setErrors({ ...error })
    if (Object.keys(error)?.length === 0) {
      setLoading(true);
      const entryNos = await outwardtoOutletStore.getEntryNo('StockadjOutlet');
      stockAdjustment.entryNo = entryNos?.entryNo ? Number(entryNos?.entryNo) + 1 : 1;
       const status ="Success"
       // await stockAdjustmentOutletStore.saveStockAdjustmentOutlet(stockAdjustment);
      if (status === "Success") {
        setLoading(false);
        setSuccessModal(true)
      }
      else {
        setLoading(false);
        setFailureModal(true);
      }
    }
  }

  const handleItems = async (val) => {
    console.log(val, 'valuedata');
    if (val != null) {
      try {
        // const getcurrentStock = await posMasterStore.loadPOSProductList(stockAdjustment?.wid);
        const itemMasterList = await GETItemwiseStockAll(2, val?.itemid, '', 1);
        console.log(itemMasterList, "dfdfitemMasterList");
        const filtergetcurrentStock = itemMasterList?.filter(each => each.itemid === val.itemId);
        console.log(filtergetcurrentStock, "getcurrentStock");
        // // Function to filter and sum the amounts
        // const getTotalCompleted = () => {
        //     return filtergetcurrentStock
        //         .reduce((total, filtergetcurrentStock) => total + filtergetcurrentStock.stock, 0);
        //     // Sum the amounts
        // };

        // console.log(getTotalCompleted, 'getTotalCompleted');
        // // const stockTotSum = 
        // console.log(filtergetcurrentStock, 'getcurrentStock');

        setStockAdjustment({
          ...stockAdjustment, itemid: val.itemid, itemName: val.itemname,
          currentStock: val?.stock,
        })

        setBarcodeList(itemMasterList);

        console.log(barcodeList, 'barcode');


      } catch (error) {

      }
    }
  };

  console.log(stockAdjustment, 'stockAdjustment');




  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

    return (

      <>
        {
          isLoading ?
            <div className='SpinnerBox'>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div> :
            <>

              <div className='container'>

                <div className='vertical-space-20'></div>
                <div className='outletInputField inputFormBox LgInputField'>
                  <div className='hrBox'>
                    <h3>Label Generation</h3>
                  </div>
                  <div className='inputBoxLists pd-b-0'>
                    <div className='LgInputListsBox mr-left-0'>
                      <div className='row'>
                        <div className="col-md-12">
                          <div className="inputBox">
                            <label>
                              Product <span>*</span>
                            </label>
                            <AutoComplete
                              options={itemMasterList}
                              value={stockAdjustment?.itemName}
                              emitOption={(newValue) => handleItems(newValue)}
                              placeholder={'Select Item..'}
                              clsName='full-width'
                              getOptionLabel='itemname'
                            />
                            <div className='vertical-space-10'></div>
                            {errors.assignItemId && <p style={{ color: 'red' }}>{errors.assignItemId}</p>}
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-md-12">
                          <div className="inputBox">
                            <label>
                              Template Name
                            </label>
                            <AutoComplete
                              options={itemMasterList}
                              value={stockAdjustment?.itemName}
                              emitOption={(newValue) => handleItems(newValue)}
                              placeholder={'Select Item..'}
                              clsName='full-width'
                              getOptionLabel='itemname'
                            />
                            <div className='vertical-space-10'></div>
                            {errors.assignItemId && <p style={{ color: 'red' }}>{errors.assignItemId}</p>}
                          </div>
                          <div className="vertical-space-10"></div>
                        </div>
                        <div className="col-md-12">
                          <label>Count</label>
                          <input
                            type='text'
                            autoComplete='nope'
                            maxLength={10}
                            className='fullInput'
                            style={{ width: "100%" }}
                            placeholder='Enter No of Count'
                            // value={outlet.mobileNo}
                            // onChange={handleChangeInput}
                            name='batch' />
                          <div className='vertical-space-10' />
                        </div>




                      </div>
                    </div>

                  </div>
                  <div className='btnBox'>
                    <button className='secondaryBtn' onClick={handleAccessModalClose}>Cancel</button>
                    <button className='dfBtn' type='submit' onClick={saveStockAdjustmentOutlet} >Print</button>
                  </div>
                </div>
                <Modal show={isReqQtyModal} onHide={handleReqQtyModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/warning.png')} />
                      </div>

                      {/* <h4>This BatchNo:{outwardOutlet.batchNo} already reached batch quantity</h4> */}
                      <h4> Please Check Less Qty</h4>
                      <h4>Actual Batch Quantity:{stockAdjustment.batchqty}</h4>
                      <h4>Entered Less Quantity:{stockAdjustment.lessQty}</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleReqQtyModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/checked.png')} />
                      </div>

                      <h4>Succesfully Submitted</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>

                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/warning.png')} />
                      </div>
                      <h4>Failed</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </>
        }
      </>
    );
  }

});

export default LabelGeneration;