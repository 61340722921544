import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Button, Image, Modal, Spinner } from 'react-bootstrap'
import AutoComplete from '../../common/shared/autoComplete'
import { useNavigate, useParams } from 'react-router-dom'
import useStores from '../../hooks';
import { IStockWarehouse, StockToWarehouseDetails } from './modal';
import { StkToWrhDetailValidation, StkToWrhValidation } from './validation';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { removeDuplicates } from '../../common/shared/utils';
import Cookies from 'js-cookie';

const StockToWarehouse = observer((): JSX.Element => {

    const navigate = useNavigate();
    const { stockToWid } = useParams()
    const { supplierMasterStore, commonStore, receiptStore, outletManagerStore, } = useStores();

    const { loadWarehouse, loadWarehouses } = commonStore;
    const { saveStockToWare, loadWarehousePackingItem, getStockToWarehouseDetails } = receiptStore;

    const [stckToWrhsSave, setStckToWrhsSave] = useState<IStockWarehouse>(new IStockWarehouse())
    const [stocktoWDet, setStocktoWDet] = useState<StockToWarehouseDetails>(new StockToWarehouseDetails())

    const [loadWarehousePackingItems, setloadWarehousePackingItems] = useState<any[]>([])
    const [tempWarehousePackingItems, settempWarehousePackingItems] = useState<any[]>([])

    console.log(loadWarehousePackingItems, stocktoWDet, 'loadWarehousePackingItems>>')
    const [errors, setError] = useState<any>({})

    const [isLoading, setLoading] = useState(true)
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [isLoadModal, setLoadModal] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canView, setCanView] = useState(false);
    // const [canPrint, setCanPrint] = useState(false);  
    // const [canUpdate, setCanUpdate] = useState(false); 

    function handleInputchnage(e) {
        const { name, value } = e.target;
        if (name === "qty" && value !== undefined) {
            let error: any = {}
            const assignQty = Number(value);
            if (assignQty! > Number(stocktoWDet?.balQty!)) {
                error.qty = "Exceeds from Batch Quantity"
            }
            setError(error);
            setStocktoWDet({ ...stocktoWDet, [name]: Number(value) })
        } else {
            setStckToWrhsSave({ ...stckToWrhsSave, [name]: value })
        }
    }

    const getproductionno = async (mode: string, vocno: any) => {

        console.log(mode, vocno, "commiddata");
        setStocktoWDet({ ...stocktoWDet, ["ttype"]: vocno })

        const Data = await loadWarehousePackingItem(2, vocno);

        console.log(Data, 'filteredData');
        setloadWarehousePackingItems(Data);
        settempWarehousePackingItems([...Data]);
        // setProductionDetails(Data)
        // setModal({ mode: mode, bool: true })
        // setSpinner(false)

    }

    const hanldeAutoComplete = (name: any, val: any) => {

        if (name === "warehouseId" && val != undefined) {
            setStckToWrhsSave({ ...stckToWrhsSave, [name]: val?.warehouseId })
        } else if (name === "Itemid" && val != undefined) {
            setStocktoWDet({ ...stocktoWDet, [name]: val?.Itemid, ctype: val?.ctype, itemname: val?.itemname, pid: val?.pid, pidid: val?.pidid })
        } else if (name === 'barcode') {
            setStocktoWDet({ ...stocktoWDet, [name]: val?.barcode })
        } else if (name === 'batchNo' && val != undefined) {
            setStocktoWDet({ ...stocktoWDet, batchNo: val?.Batchno, balQty: val?.Balqty, Mrprate: val?.Mrprate })
        } else if (name === 'pid') {
            setStocktoWDet({ ...stocktoWDet, ttype: (val) })

        }
    }

    function handleAddtoGrid() {
        let error: any = {};
        error = StkToWrhDetailValidation(stocktoWDet, stckToWrhsSave);
        setError(error)
        console.log(Object.keys(error)?.length, "validationerror");
        if (Object.keys(error)?.length === 0) {
            if (!stckToWrhsSave?.stocktoWarehousedetails) {
                stckToWrhsSave.stocktoWarehousedetails! = new Array<StockToWarehouseDetails>();
            }
            stckToWrhsSave?.stocktoWarehousedetails?.push(stocktoWDet)
            setStckToWrhsSave(stckToWrhsSave)
            let updatedList = loadWarehousePackingItems?.map((each) => {
                if (each?.Itemid === stocktoWDet?.Itemid && each?.Batchno === stocktoWDet?.batchNo && each?.pid === stocktoWDet?.pid) {
                    each.Balqty = Number(each.Balqty) - stocktoWDet?.qty!;
                    return each
                }
                return each
            })
            setloadWarehousePackingItems([...updatedList]);
            setStocktoWDet({ ...stocktoWDet, pid: 0, Itemid: 0, itemname: '', barcode: '', batchNo: '', balQty: '', qty: 0 })
        }
    }

    useEffect(() => {
        handleOutRequestItemList()
    }, [stckToWrhsSave?.stocktoWarehousedetails?.length])

    function handleOutRequestItemList() {
        let tempWPackingItems = [...tempWarehousePackingItems];
        if (tempWPackingItems?.length) {
            let updatedList: any[] = [];
            tempWPackingItems?.map((obj, index) => {
                let filterItems = stckToWrhsSave?.stocktoWarehousedetails?.filter((item) => item?.Itemid === obj?.Itemid && item?.batchNo === obj?.Batchno)
                let findBalQty = filterItems?.reduce((acc, valu) => acc = acc + Number(valu?.qty ?? 0), 0)
                if (findBalQty! < obj?.Balqty) {
                    updatedList?.push(obj)
                }
            })
            setloadWarehousePackingItems([...updatedList])
        }
    }

    function handleRemove(index) {
        let findObj = stckToWrhsSave!.stocktoWarehousedetails![index];
        let updatedList = loadWarehousePackingItems?.map((each) => {
            if (findObj && each?.Itemid === findObj?.Itemid && each?.Batchno === findObj?.batchNo && each?.pid === findObj?.pid) {
                each.Balqty = Number(each.Balqty) + findObj?.qty!;
                return each
            }
            return each
        })
        setloadWarehousePackingItems([...updatedList]);
        stckToWrhsSave!.stocktoWarehousedetails!.splice(index, 1)
        setStckToWrhsSave(_.cloneDeep(stckToWrhsSave))
    }

    async function editItem(id) {
        const filterData = stckToWrhsSave?.stocktoWarehousedetails?.find((item) => item?.Itemid === id)
        console.log(filterData, "aaasfilterData");
        const removeFilterData = stckToWrhsSave?.stocktoWarehousedetails?.filter((each) => each.Itemid !== id)
        if (filterData) {
            const fieldValues = {
                Itemid: filterData?.Itemid,
                itemname: filterData?.itemname,
                qty: filterData?.qty,
                Mrprate: filterData?.Mrprate,
                batchNo: filterData?.batchNo,
                barcode: filterData?.barcode,
                balQty: filterData?.balQty,
                ttype: filterData?.ttype,
                ctype: filterData?.ctype,
                pidid: filterData?.pidid,
            }
            setStocktoWDet(fieldValues);
            if (removeFilterData) {
                stckToWrhsSave.stocktoWarehousedetails = removeFilterData;
                setStckToWrhsSave({ ...stckToWrhsSave })
            }
        }
    }

    function handleCancel() {
        navigate('/Admin/StockToWarehouse/List')
    }

    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
    }

    async function saveDamageEntryWarehouse() {

        let error: any = {};
        error = StkToWrhValidation(stckToWrhsSave);
        setError(error)
        if (Object.keys(error)?.length === 0) {
            const sumbitArr = stckToWrhsSave?.stocktoWarehousedetails?.map((obj) => {
                let newObj = {
                    ...obj,
                    stocktoWId: stckToWrhsSave?.stocktoWId
                }
                return newObj
            })

            stckToWrhsSave.stocktoWarehousedetails = sumbitArr
            // const status = "Success";
            const status = await saveStockToWare(stckToWrhsSave);

            if (status === "Success") {
                setSuccessModal(true);
                setLoading(false)
            } else {
                setFailureModal(true)
                setLoading(false)
            }
        }
    }

    function mapToData(data) {

        let findObj = receiptStore?.stockToWarehouseList?.find((obj) => obj?.StocktoWId === Number(stockToWid))
        stckToWrhsSave.stocktoWId = findObj?.StocktoWId;
        stckToWrhsSave.stocktoWDate = findObj?.StocktoWDate;
        stckToWrhsSave.warehouseId = findObj?.WarehouseId;

        let objArr = data?.map((obj) => {
            let mapObj: StockToWarehouseDetails = {
                Itemid: obj?.Itemid,
                itemname: obj?.itemname,
                qty: obj?.Qty,
                Mrprate: obj?.Mrprate,
                batchNo: obj?.BatchNo,
                barcode: obj?.Barcode,
                stocktoWId: obj?.StocktoWdId
            }
            return mapObj
        })
        stckToWrhsSave.stocktoWarehousedetails = objArr;
        setStckToWrhsSave(stckToWrhsSave)

    }

    const isInitialRender = useRef(true);

    async function fetchPreLoadingData() {
        if (stockToWid) {
            const [paymentList] = await Promise.all([getStockToWarehouseDetails(stockToWid), getStockToWarehouseDetails()])
            if (paymentList) {

                mapToData(paymentList)
                setStckToWrhsSave({ ...stckToWrhsSave, stocktoWId: Number(stockToWid) })
            }
        } else {
            const entryNo = await outletManagerStore.getEntryNo('Stocktowarehouse');
            setStckToWrhsSave({ ...stckToWrhsSave, stocktoWId: (entryNo?.entryNo + 1) })
        }
        setLoading(false);
        const [loadWarehousePackingItems] = await Promise.all([loadWarehousePackingItem(1, ''), loadWarehouse()])
        console.log(loadWarehousePackingItems, "loadWarehousePackingItemsaaa");
        setloadWarehousePackingItems(loadWarehousePackingItems);
        settempWarehousePackingItems([...loadWarehousePackingItems]);
    }

    // useEffect(() => {
    //     if (isInitialRender.current) {
    //         fetchPreLoadingData();
    //         isInitialRender.current = false;
    //     } else {
    //     }
    //     return () => {
    //         // Cleanup logic (if needed)
    //     };
    // }, [])
    const isCurrenPage = useRef(true)
    const empid = Number(Cookies.get('userId'));
    const formName = 'Stock_to_warehouse';

    useEffect(() => {

        const fetchRights = async () => {

            try {
                const data = await supplierMasterStore.fetchRights(empid, formName);
 
                if (Array.isArray(data) && data.length > 0) {
                    const specificFieldValue = data[0].sts;
                    const rightsValue = data[0].rights;
                    const lettersArray = rightsValue.split('');
                    if (specificFieldValue === 1) {
                        if (isCurrenPage.current) {
                            fetchPreLoadingData();
                            isCurrenPage.current = false
                            if (lettersArray.includes('a')) {
                                setCanAdd(true);
                            } else {
                                navigate('/Admin/StockToWarehouse/List')
                            }
                            if (lettersArray.includes('e')) {
                                setCanEdit(true);
                            }
                            // if (lettersArray.includes('v')) {
                            //   setCanView(true); 
                            // }     
                            // if (lettersArray.includes('p')) {
                            //   setCanPrint(true); 
                            // }       
                            // if (lettersArray.includes('u')) {
                            //   setCanUpdate(true); 
                            // }                     
                        }
                    } else if (specificFieldValue === 0) {
                        setLoadModal(true)
                    }
                }

            } catch (error) {
                console.error("Error fetching rights:", error);
            }
        };
        fetchRights();
    }, [empid, formName]);

    if (isLoadModal) {
        return (
            <div className="container">
                {isLoadModal && <h1>Access Denied</h1>}
                <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Access Denied</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    else {


        return (
            <>
                {/* {
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>} */}
                <div className="container">
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox mdInputField'>
                        <div className='hrBox' >
                            <h3>Stock to Warehouse</h3>
                        </div>
                        <div className='inputBoxLists'>
                            <div className="row">
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>Entry No <span>*</span></label>
                                        <input type="text" className=''
                                            name='stocktoWId' disabled
                                            value={stckToWrhsSave.stocktoWId}
                                            style={{ width: "100%" }} placeholder='Entry No..'></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>Entry Date <span>*</span></label>
                                        <input type="date" style={{ width: "100%" }}
                                            onChange={handleInputchnage}
                                            name="stocktoWDate"
                                            value={stckToWrhsSave.stocktoWDate = stckToWrhsSave?.stocktoWDate ?? new Date().toISOString().substr(0, 10)}
                                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                        {errors.stocktoWDate && <p style={{ color: 'red' }}>{errors.stocktoWDate}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='vertical-space-10'></div>
                                <div className='col-md-12'>
                                    <div className='inputBox'>
                                        <label>Warehouse <span>*</span></label>
                                        <AutoComplete clsName='full-width' getOptionLabel='warehouseName' options={loadWarehouses}
                                            placeholder='Select Warehouse'
                                            value={loadWarehouses?.length ?
                                                loadWarehouses?.find((obj) => obj?.warehouseId === stckToWrhsSave?.warehouseId)?.warehouseName :
                                                stckToWrhsSave?.warehouseId}
                                            emitOption={(option) => hanldeAutoComplete("warehouseId", option)} />
                                        {errors.warehouseId && <p style={{ color: 'red' }}>{errors.warehouseId}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='inputBox'>
                                        <label>Production Entry No <span>*</span></label>
                                        <select className='full-width'
                                            aria-placeholder='Select Production No'
                                            value={stocktoWDet?.ttype}
                                            onChange={(event) => getproductionno("pid", event?.target?.value)}>
                                            <option selected value={""}>Select</option>
                                            {removeDuplicates(loadWarehousePackingItems, 'ttype')?.map((each, index) => {
                                                return (
                                                    <option key={index} value={`${each?.ttype}`}>{each?.ttype}</option>
                                                )
                                            })}
                                        </select>
                                        {errors.ttype && <p style={{ color: 'red' }}>{errors.ttype}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='inputBox'>
                                        <label>Item <span>*</span></label>
                                        <AutoComplete clsName='full-width'
                                            options={loadWarehousePackingItems}
                                            placeholder='Select Item' value={stocktoWDet?.itemname}
                                            emitOption={(option) => hanldeAutoComplete("Itemid", option)} getOptionLabel='itemname' />
                                        {errors.Itemid && <p style={{ color: 'red' }}>{errors.Itemid}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='inputBox'>
                                        <label>Barcode <span>*</span></label>
                                        <AutoComplete
                                            clsName='full-width'
                                            options={loadWarehousePackingItems?.filter(each => each?.Itemid === stocktoWDet?.Itemid)}
                                            placeholder='Select Barcode' value={stocktoWDet?.barcode}
                                            emitOption={(option) => hanldeAutoComplete("barcode", option)} getOptionLabel='barcode' />
                                        {errors.barcode && <p style={{ color: 'red' }}>{errors.barcode}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='inputBox'>
                                        <label>Batch No <span>*</span></label>
                                        <AutoComplete clsName='full-width' options={loadWarehousePackingItems?.filter(each => each?.Itemid === stocktoWDet?.Itemid && each?.barcode === stocktoWDet?.barcode)}
                                            placeholder='Select Item'
                                            value={stocktoWDet?.batchNo}
                                            emitOption={(option) => hanldeAutoComplete("batchNo", option)}
                                            getOptionLabel='Batchno'
                                        />
                                        {errors.batchNo && <p style={{ color: 'red' }}>{errors.batchNo}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='inputBox'>
                                        <label>MRP <span>*</span></label>
                                        <input type="number" className='' style={{ width: "100%" }}
                                            name='qty'
                                            value={stocktoWDet?.Mrprate}
                                            onChange={handleInputchnage}
                                            placeholder='Mrp..'></input>
                                        {errors.qty && <p style={{ color: 'red' }}>{errors.qty}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='inputBox'>
                                        <label>Qty <span>*</span></label>
                                        <input type="number" className='' style={{ width: "100%" }}
                                            name='qty'
                                            value={stocktoWDet?.qty}
                                            onChange={handleInputchnage}
                                            placeholder='Qty..'></input>
                                        {errors.qty && <p style={{ color: 'red' }}>{errors.qty}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>UOM<span>*</span></label>
                                        <input type="text" className=''
                                            value={stocktoWDet?.Itemid ? loadWarehousePackingItems?.find((obj) => obj?.Itemid === stocktoWDet?.Itemid)?.UOM : ''}
                                            disabled 
                                            name="uom"
                                            style={{ width: "100%" }} placeholder='Uom..'></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>Balance Qty <span>*</span></label>
                                        <input type="text" style={{ width: '100%' }}
                                            name="balQty"
                                            disabled
                                            value={Number(stocktoWDet?.balQty! ?? 0) - Number(stocktoWDet?.qty ?? 0)}
                                            placeholder="Select Batch No.."
                                        ></input>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='btnBox justify-flex-start pd-t-0'>
                                        <button className='dfBtn' type='submit' onClick={handleAddtoGrid}>ADD</button>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>
                            </div>
                            <div className='vertical-space-10'></div>
                            <div className='tableBox' style={{ position: 'relative', maxHeight: '300px', overflow: 'auto' }}>
                                <table className="table table-striped">
                                    <thead style={{ position: 'sticky', top: 0 }}>
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Item</th>
                                            <th scope="col">Barcode </th>
                                            <th scope="col">BatchNo</th>
                                            <th scope="col">Qty</th>
                                            <th scope="col">UOM</th>
                                            <th scope="col">MRP</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {stckToWrhsSave?.stocktoWarehousedetails?.map((val, key) => {
                                            console.log(val, "valaasasasasasas");
                                            return (
                                                <tr key={key}>
                                                    <td>{key + 1}</td>
                                                    <td>{val?.itemname}</td>
                                                    {/* <td>{val?.UOM}</td> */}
                                                    <td>{val?.barcode}</td>
                                                    <td>{val?.batchNo}</td>
                                                    <td>{val.qty}</td>
                                                    <td>{val.Mrprate}</td>
                                                    <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit' onClick={() => editItem(val.Itemid)}>Edit</button></td>
                                                    <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit' onClick={() => handleRemove(key)}>Remove</button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {errors.stocktoWarehousedetails && <p style={{ color: 'red' }}>{errors.stocktoWarehousedetails}</p>}
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='btnBox'>
                                <button className='secondaryBtn' onClick={handleCancel}>List</button>
                                <button className='dfBtn' type='submit' onClick={saveDamageEntryWarehouse}>Submit</button>
                            </div>
                        </div>
                    </div>
                    <Modal show={isSuccessModal} onHide={handleCancel} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>
                                <div className='imgBox'>
                                    <Image src={require('../../../../image/checked.png')} />
                                </div>

                                <h4>Succesfully Submitted</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCancel}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>

                                <div className='imgBox'>
                                    <Image src={require('../../../../image/warning.png')} />
                                </div>
                                <h4>Failed</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setFailureModal(false)}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        )
    }
})

export default StockToWarehouse